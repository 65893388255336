import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { Provider } from "react-redux";
// import { lazy, Suspense } from "react";
import { LoadScript } from "@react-google-maps/api";
// import configureStore from "./store/store";
// import Layout from "./components/Layout";
// import ReRoutes from "./components/ReRoutes";

import Home from "./pages/DestinationServicesStatic/pricing/Home";
import ContactUs from "./pages/DestinationServicesStatic/pricing/ContactUs";
import Pricing from "./pages/DestinationServicesStatic/pricing/Pricing";
import { NoUserHeaderMenu } from "./common/header/Header";
import NoPageFound from "./pages/DestinationServicesStatic/noPageFound/NoPageFound";
import Layout from "./common/layout/Layout";
import Login from "./pages/DestinationServicesStatic/authLogin/Login";
import NewHomePage from "./pages/DestinationServicesStatic/home/newHomePage";
//  supplier routes
import SupplierLayout from "./pages/Supplier/SupplierLayout";
import Dashboard from "./pages/Supplier/Dashboard/Dashboard";
import ConsultantList from "./pages/Supplier/ConsultantList/ConsultantList";
import ClientProfileList from "./pages/Supplier/ClientProfileList/ClientProfileList";
import Reports from "./pages/Supplier/Reports/Reports";
import AppointementReports from "./pages/Supplier/Reports/AppointementReports";
import TransfereeReports from "./pages/Supplier/Reports/TransfereeReports";
import BillingReports from "./pages/Supplier/Reports/BillingReports";
import SupplierTransfereeList from "./pages/Supplier/TransfereeList/SuppliertransfereeList";
import Transfereemoredetails from "./pages/Supplier/moredetails/transfereemoredetails";

// ---------------------

// Transferee routes
import GetStartedFirstTime from "./pages/Transferee/getStarted/getStartedFirstTime";
import TransfereeLayout from "./pages/Transferee/TransfereeLayout";
import Dslanding from "./pages/Transferee/dsHome/Dslanding";
import DSHelp from "./pages/Transferee/dsHome/dsHelp";
import GetStarted from "./pages/Transferee/getStarted/getStarted";
import Citylist from "./pages/Transferee/cityList/cityList";
// import CityLanding from "./pages/Transferee/cityLanding/cityLanding";
import CityLanding from "./pages/Transferee/cityLanding/cityLanding";
import ResourceLanding from "./pages/Transferee/resume/resource";
import RentersResume from "./pages/Transferee/resume/rentersResume";
import PetResume from "./pages/Transferee/resume/petResume ";
import DieDetails from "./pages/Transferee/DieDetails/dieDetails";
import MyProperties from "./pages/Transferee/myProperties/myProperties";
import CheckList from "./pages/Transferee/checkList/CheckList";
import DsProfile from "./pages/Transferee/dsProfile/dsProfile";
import DsSoloProfile from "./pages/Transferee/dsProfile/dsSoloProfile";
import MyPropertyDetails from "./pages/Transferee/myProperties/myPropertyDetails";
import SoloLanding from "./pages/Transferee/solo/soloLanding";
import CommanderLanding from "./pages/Transferee/solo/commanderLanding";
import Maplist from "./pages/Transferee/compareMapList/maplist";
import Gdpr from "./pages/Transferee/gdpr/gdpr";
// --------------------

// consultant routes
import ConsultantLayout from "./pages/Consultant/ConsultantLayout";
import ConsultantDashboard from "./pages/Consultant/ConsultantDashboard/ConsultantDashboard";
import Schedule from "./pages/Consultant/Schedule/Schedule";
import TransfereeList from "./pages/Consultant/Transeferee/TransfereesList";
import MoreDetail from "./pages/Consultant/moredetails/moredetails";
import Properties from "./pages/Consultant/Property/Properties";
import ConsultantReports from "./pages/Consultant/reports/ConsultantReports";
import ConsultantAppointementReports from "./pages/Consultant/reports/ConsultantAppointementReports";
import ConsultantTransfereeReports from "./pages/Consultant/reports/ConsultantTransfereeReports";
import UsageDashboard from "./pages/Consultant/UsageDashbord /UsageDashboard";
import Chat from "./pages/Consultant/chat/Chat";
import Chatting from "./pages/Consultant/chat/chatting";
import ConsultantProfile from "./pages/Consultant/ConsultantProfile/ConsultantProfile";
import MyProperty from "./pages/DestinationServicesStatic/myProperties/myPropertyDetails";
import LoginForce from "./pages/DestinationServicesStatic/authLogin/LoginForce";
import MapBoxPractice from "./common/mapBoxPractice";
import MapDrawCircle from "./pages/Transferee/cityLanding/googleMapdraw";
import NewPublicPage from "./pages/DestinationServicesStatic/newpublicPage/newPublicPage";
import PublicContactUs from "./pages/DestinationServicesStatic/newpublicPage/common/publicContactUs";
import PublicLayout from "./pages/DestinationServicesStatic/newpublicPage/publicLayout/publicLayout";
import ForIndividuals from "./pages/DestinationServicesStatic/newpublicPage/forIndividuals";

import RentCalculatorPage from "./pages/Transferee/resume/rentCalculator";
import RentalFAQ from "./pages/Transferee/resume/rentalFAQ";
import ForConsultant from "./pages/DestinationServicesStatic/newpublicPage/forConsultant";
import ForCompaniesPage from "./pages/DestinationServicesStatic/newpublicPage/forCompanies";
import BaseMap from "./pages/Transferee/cityLanding/map/BaseMap";
//ai assistant
import AIAssistant from "./pages/Transferee/aiAssistant/aiAssistant";
// --------------------

const AllRoutes = () => {
  return (
    <LoadScript
      googleMapsApiKey={GoogleApiKey}
      libraries={["places", "drawing"]}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/mapBox" element={<MapBoxPractice />} />
          <Route path="/mapdraw" element={<MapDrawCircle />} />
          <Route path="/newHome" element={<NewHomePage />}></Route>

          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/loginforce" element={<LoginForce />} />
          <Route path="/." element={<NoUserHeaderMenu />} />
          <Route path="/page-not-found" element={<NoPageFound />} />
         
          <Route path="" element={<PublicLayout />}>
            <Route path="/index-info" element={<NewPublicPage />}></Route>
            <Route path="/contact-us" element={<PublicContactUs />}></Route>
            <Route path="/forCompanies" element={<ForCompaniesPage />}></Route>
            <Route path="/forConsultant" element={<ForConsultant />}></Route>
            <Route path="/individuals" element={<ForIndividuals />}></Route>
          </Route>

          <Route path="" element={<Layout />}>
            <Route path="/home" element={<Home />}></Route>

            <Route path="/homee" element={<Home />}></Route>
            <Route path="/pricing" element={<Pricing />}></Route>
            <Route path="/contactus" element={<ContactUs />}></Route>
          </Route>
          <Route path="/solo-landing" element={<SoloLanding />} />
          <Route path="/commander-landing" element={<CommanderLanding />} />
          <Route path="/getStartedFirst" element={<GetStartedFirstTime />} />

          {/* supplier routes starts */}
          <Route path="" element={<SupplierLayout />}>
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/ConsultantList" element={<ConsultantList />} />
            <Route
              path="/SupplierTransfereeList"
              element={<SupplierTransfereeList />}
            />
            <Route
              path="/transfereeMore-details"
              element={<Transfereemoredetails />}
            />
            <Route path="/supplierSADetails" element={<MyProperty />} />
            <Route path="/ClientProfileList" element={<ClientProfileList />} />
            <Route path="/reports" element={<Reports />} />
            <Route
              path="/appointementreports"
              element={<AppointementReports />}
            />
            <Route path="/transfereereports" element={<TransfereeReports />} />
            <Route path="/billingreports" element={<BillingReports />} />
          </Route>
          {/*transferee routes */}
          <Route path="" element={<TransfereeLayout />}>
            <Route path="/dsLanding" element={<Dslanding />} />
            <Route path="/map-box-test" element={<BaseMap />} />
            <Route path="/dsHelp" element={<DSHelp />} />
            <Route path="/getStarted" element={<GetStarted />} />
            <Route path="/citylist" element={<Citylist />} />
            <Route path="/city-landing" element={<CityLanding />} />
            <Route path="/aiAssistant" element={<AIAssistant />} />
            {/* <Route path="/city-landing-new" element={<CityLanding />} /> */}
            <Route path="/myProperty" element={<MyProperties />} />
            <Route path="/resources" element={<ResourceLanding />} />
            <Route
              path="/rentCalculator"
              element={<RentCalculatorPage />}
            ></Route>
            <Route path="/rental-faq" element={<RentalFAQ />}></Route>
            <Route path="/rentersresume" element={<RentersResume />} />
            <Route path="/petresume" element={<PetResume />} />
            <Route path="/Dei-data" element={<DieDetails />} />
            <Route path="/checklist" element={<CheckList />} />
            <Route path="/dsProfile" element={<DsProfile />} />
            <Route
              path="/my_property_details"
              element={<MyPropertyDetails />}
            />
            <Route path="/singleProfile" element={<DsSoloProfile />} />
            <Route path="/CompareMapList" element={<Maplist />} />
            {/* <Route path="/gdpr" element={<Gdpr />} /> */}
          </Route>
          {/* consultant routes */}
          <Route path="" element={<ConsultantLayout />}>
            <Route path="/SADetails" element={<MyProperty />} />
            <Route
              path="/ConsultantDashboard"
              element={<ConsultantDashboard />}
            />
            <Route
              path="/ConsultantusageDashboard"
              element={<UsageDashboard />}
            />
            <Route path="/ConsultantSchedules" element={<Schedule />} />
            <Route
              path="/ConsultantTransfereeList"
              element={<TransfereeList />}
            />
            <Route path="/more-details" element={<MoreDetail />} />
            <Route path="/Properties" element={<Properties />} />
            <Route path="/consultantreports" element={<ConsultantReports />} />
            <Route
              path="/consultantappointementreports"
              element={<ConsultantAppointementReports />}
            />

            <Route
              path="/consultanttransfereereports"
              element={<ConsultantTransfereeReports />}
            />

            {/* <Route path="/Consultant" element={<Chat />} /> */}
            <Route path="/consultant" element={<Chatting />} />
            <Route path="/ConsultantProfile" element={<ConsultantProfile />} />
          </Route>

          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </BrowserRouter>
    </LoadScript>
  );
};

export default AllRoutes;

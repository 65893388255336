import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { Table, Spin } from "antd";
import DatePicker from "react-datepicker";
import LoginStore from "../../../../services/thirdPartyService";
import ApiStore from "../../../../services/apiServices";
import * as $ from "jquery";
import Header from "../header and footer/Header";
import { CSVLink } from "react-csv";
import "./reports.css";
// import Footer from "../header and footer/Footer";
import Footer from "../../../common/footer/footerWithoutchat";

const Appointmentreport = () => {
  const intialState = {
    startDate: null,
    endDate: null,
    Company: "",
    dateError: { startDate: "", endDate: "" },
    reportslist: [],
    supplierid: "",
    loader: false,
    columns: [
      // {
      //   title: "Consultant Email",
      //   dataIndex: "consultantemail",
      //   key: Math.random(),
      // },
      {
        title: "Transferee Email",
        dataIndex: "transfereeemail",
        key: "transfereeemail",
        sorter: (a, b) => a.transfereeemail.localeCompare(b.transfereeemail),
        columnTitle: "Transferee Email",
      },
      {
        title: "Service level",
        dataIndex: "servicelevel",
        key: "service level",
        sorter: (a, b) => a.servicelevel.localeCompare(b.servicelevel),
      },
      // {
      //   title: "Company",
      //   dataIndex: "company",
      //   key: "company",
      //   sorter: (a, b) => a.company.localeCompare(b.company),
      // },
      {
        title: "Appointment",
        dataIndex: "appointmenttype",
        key: "appointment type",
        sorter: (a, b) => a.appointmenttype.localeCompare(b.appointmenttype),
      },
      {
        title: "Mode",
        dataIndex: "mode",
        key: "mode",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          } else return 0;
        },
      },
      {
        title: "Time",
        dataIndex: "",
        key: "x",
        render: (text, record) => {
          return (
            <span>
              {record.fromtime}-{record.totime}
            </span>
          );
        },
        sorter: (a, b) => {
          const [fromTimeA, toTimeA] = a.split("-");
          const [fromTimeB, toTimeB] = b.split("-");
          const timeA =
            parseInt(fromTimeA.replace(":", "")) +
            parseInt(toTimeA.replace(":", ""));
          const timeB =
            parseInt(fromTimeB.replace(":", "")) +
            parseInt(toTimeB.replace(":", ""));
          return timeA - timeB;
        },
      },
    ],
  };

  const [state, setState] = useState(intialState);

  // function handleDateChange(e, date) {
  //   let object = {};
  //   object[date] = e;
  //   if (date == "startDate") {
  //     updateState(object);
  //   } else {
  //     updateState(object);
  //   }
  // }
  function handleDateChange(e, date) {
    let object = {};
    object[date] = e;

    if (date === "startDate") {
      if (!e) {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            startDate: "From date is required",
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            startDate: "",
          },
        }));
      }
    } else {
      if (!e) {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            endDate: "To date is required",
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            endDate: "",
          },
        }));
      }
    }
  }
  async function updateState(values) {
    await setState((prev) => ({ ...prev, ...values }));
  }

  useEffect(() => {
    loginCheck();
  }, []);

  async function loginCheck() {
    let getSessionData = await LoginStore.getLoginInfo({});
    updateState({
      supplierid: getSessionData.supplieruid,
      consultantuniqueId: getSessionData.userUniqueId,
    });
  }

  async function onSubmit(e) {
    e.preventDefault();
    let errorUpdate = 0;

    if (state.startDate == null) {
      $("#startdate").addClass("is-invalid");
      setState((prevState) => ({
        ...prevState,
        dateError: {
          ...prevState.dateError,
          startDate: "From date is required",
        },
      }));
      errorUpdate++;
    } else {
      $("#startdate").removeClass("is-invalid");
    }
    if (state.endDate == null) {
      $("#enddate").addClass("is-invalid");
      setState((prevState) => ({
        ...prevState,
        dateError: {
          ...prevState.dateError,
          endDate: "To date is required",
        },
      }));
      errorUpdate++;
    } else {
      $("#enddate").removeClass("is-invalid");
    }

    if (errorUpdate == 0) {
      let data = {
        supplieruniqueId: state.supplierid,
        consultantuniqueId: state.consultantuniqueId,
        fromdate: moment(state.startDate).format("MM/DD/YYYY"),
        todate: moment(state.endDate).format("MM/DD/YYYY"),
      };
      await updateState({ loader: true });
      const GetConsultantAppointmentsReport =
        await ApiStore.GetConsultantAppointmentsReport(data);

      if (GetConsultantAppointmentsReport.error == 0) {
        let GSAReport =
          GetConsultantAppointmentsReport.transfereeappoinmentsList;
        GSAReport = GSAReport.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
        GSAReport.map(function (item) {
          item.date = moment(item.date).format("MM/DD/YYYY");
          return item;
        });
        await updateState({ reportslist: GSAReport, loader: false });
      } else {
        await updateState({ loader: false });
      }
    }
  }
  return (
    <div>
      {/* <Header /> */}
      <div className="consultant-report-views">
        <div className="container">
          <div className="consultant-report-views-inputvalues">
            <div className="heaidng-view">
              <h1>Appointment Report</h1>
              {state.reportslist.length > 0 && (
                <CSVLink
                  filename={"Appointmentreport.csv"}
                  data={state.reportslist}
                  className="btn"
                >
                  Export CSV
                </CSVLink>
              )}
              {/* <button type="button" className="btn">Export CSV</button> */}
            </div>
            <form className="consultant-reports-data-select">
              <div className="row">
                <div className="col-md-10">
                  <div className="form-row row">
                    <div className="form-group col-md-4">
                      <label>From: </label>

                      <div
                        id="datepickera"
                        className="input-group date"
                        data-date-format="mm-dd-yyyy"
                      >
                        <DatePicker
                          id="startdate"
                          dateFormat="MM/dd/yyyy"
                          selected={state.startDate}
                          selectsStart
                          // minDate={new Date()}
                          maxDate={state.endDate}
                          onChange={(e) => handleDateChange(e, "startDate")}
                          placeholderText="Select start date ..."
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                      {state.dateError.startDate && (
                        <div className="text-danger">
                          {state.dateError.startDate}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-4">
                      <label>To: </label>
                      <div
                        id="datepicker"
                        className="input-group date"
                        data-date-format="mm-dd-yyyy"
                      >
                        <DatePicker
                          id="enddate"
                          dateFormat="MM/dd/yyyy"
                          selected={state.endDate}
                          selectsEnd
                          autoComplete="off"
                          startDate={state.startDate}
                          endDate={state.endDate}
                          onChange={(e) => handleDateChange(e, "endDate")}
                          minDate={state.startDate}
                          placeholderText="Select end date ..."
                          className="form-control"
                        />
                      </div>
                      {state.dateError.endDate && (
                        <div className="text-danger">
                          {state.dateError.endDate}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <button
                    type="button"
                    onClick={(e) => onSubmit(e)}
                    className="btn"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="form-row row"></div>
            </form>
          </div>
          {state.loader == false ? (
            <div className="reports appintment-table">
              <div className="table-responsive">
                <Table
                  dataSource={state.reportslist}
                  columns={state.columns}
                  pagination={{
                    defaultPageSize: 20,
                    locale: { items_per_page: "" },
                  }}
                />
              </div>
            </div>
          ) : (
            <Spin />
          )}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Appointmentreport;

import React, { useEffect, useState, useRef } from "react";
import LoginStore from "../../../../services/thirdPartyService";
import aiAssistant from "../../../../services/aiAssistant";
import OwlCarousel from "react-owl-carousel";

function AIAssistant() {
  const [state, setState] = useState({
    query: "",
    sessionData: "",
    response: false,
    messages: [],
    startPosition: 0,
    tabItems: "",
    optionTypes: [
      {
        name: "Dining & Events",
        type: "dining&events",
        faicon: "fa-regular fa-fork-knife",
      },
      {
        name: "Delivery",
        type: "delivery",
        faicon: "fa-solid fa-map-pin",
      },
      {
        name: "Local Services",
        type: "localservices",
        faicon: "fa-light fa-location-smile",
      },
      {
        name: "Shopping",
        type: "shopping",
        faicon: "fa-regular fa-cart-shopping",
      },
      {
        name: "Travel",
        type: "travel",
        faicon: "fa-solid fa-person-walking-luggage",
      },
      {
        name: "New Paper",
        type: "newpaper",
        faicon: "fa fa-newspaper",
      },
    ],
    selectedType: "dining&events",
  });
  const chatHistoryRef = useRef(null);

  const updateState = (obj) => {
    setState((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  useEffect(() => {
    fetchData();
    tab();
  }, []);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [state.messages]);

  const fetchData = async () => {
    let sessionData = await LoginStore.getLoginInfo({});
    updateState({ sessionData: sessionData });
  };

  const handleChange = (e) => {
    updateState({ query: e.target.value });
  };

  const formatTimestamp = (date) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const dateOptions = {
      month: "short",
      day: "2-digit",
    };
    const timeString = new Intl.DateTimeFormat("en-US", options).format(date);
    const dateString = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date
    );

    return `${timeString}, ${dateString}`;
  };

  const handleClick = async () => {
    if (!state.query.trim()) return;
    const timestamp = formatTimestamp(new Date());

    // Add user message
    updateState({
      messages: [
        ...state.messages,
        {
          message: state.query,
          timestamp,
          type: "sender",
        },
      ],
      response: true,
      query: "",
    });

    let object = {
      query: state.query,
      transferee_uid: state.sessionData.userUniqueId,
    };

    const aiResponse = await aiAssistant.aiAssistantResponse(object);

    if (aiResponse) {
      updateState({
        messages: [
          ...state.messages,
          {
            message: state.query,
            timestamp,
            type: "sender",
          },
          {
            message: aiResponse,
            timestamp: formatTimestamp(new Date()),
            type: "receiver",
          },
        ],
        response: false,
      });
    }
  };

  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };

  const tab = () => {
    const screenWidth = window.innerWidth;
    let tabItems =
      parseInt(screenWidth) <= 576
        ? 1
        : parseInt(screenWidth) <= 767
        ? 2
        : parseInt(screenWidth) <= 991
        ? 3
        : parseInt(screenWidth) <= 1199
        ? 3
        : 5;
    setState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };

  const handleData = (selectedType) => {
    updateState({ selectedType: selectedType.type });
  };

  // const contentMapping = {
  //   "dining&events": [
  //     "Find a restaurant with a great happy hour for next Wed for 6 people.",
  //     "Find a sushi restaurant with high ratings and reservations tonight for 2.",
  //     "Reserve a table for 8 at a seafood restaurant next Friday at 6 PM.",
  //   ],
  //   delivery: [
  //     "Find pizza delivery near me within 30 minutes.",
  //     "Order groceries for same-day delivery.",
  //     "Get dessert delivered to your location in under an hour.",
  //   ],
  //   localservices: [
  //     "Find a nearby car repair shop.",
  //     "Book a cleaning service for tomorrow morning.",
  //     "Find a pet grooming service available this weekend.",
  //   ],
  // };

  return (
    <div
      className={
        state.messages.length > 0
          ? "relo-aiAssistant withChat"
          : "relo-aiAssistant "
      }
    >
      <h2>How can I help you?</h2>

      {/* Chat Container */}
      {state.messages.length > 0 && (
        <div className="chatHistory" ref={chatHistoryRef}>
          {state.messages.map((message, index) => (
            <div className={`chatView ${message.type}`} key={index}>
              <span>
                {message.type === "receiver" && (
                  <img src="assets/images/new-logo.svg" alt="" />
                )}
                {message.timestamp}
              </span>

              <p style={{ whiteSpace: "pre-wrap" }}>{message.message}</p>
            </div>
          ))}
        </div>
      )}

      {/* Input and Submit Button */}
      <div className="textHere">
        <textarea
          value={state.query}
          onChange={(e) => handleChange(e)}
          rows="5"
          cols="40"
          placeholder="Type here..."
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && state.query.trim() !== "") {
              e.preventDefault();
              handleClick();
            }
          }}
        />
        {!state.response ? (
          <button
            onClick={handleClick}
            className="btn"
            disabled={state.query.trim() === ""}
          >
            <i className="fa-solid fa-arrow-up"></i>
          </button>
        ) : (
          <button className="btn">
            <i className="fa-solid fa-spinner"></i>
          </button>
        )}
      </div>

      {state.messages.length == 0 && (
        <>
          <div className="prompts-list">
            <OwlCarousel
              key={state.selectedType}
              className="owl-theme"
              dots={false}
              nav={true}
              autoWidth={true}
              navText={[
                "<i class='fa-light fa-angle-left'></i>",
                "<i class='fa-light fa-angle-right'></i>",
              ]}
              items={state.tabItems}
              startPosition={state.startPosition}
              onDragged={(object) => updateCarouselPosition(object)}
              onTranslated={(object) => updateCarouselPosition(object)}
            >
              {state.optionTypes.map((type, index) => {
                return (
                  <div
                    key={type.name}
                    className={`tail ${
                      state.selectedType === type.type ? "active" : " "
                    }`}
                    onClick={() => handleData(type)}
                  >
                    <span onClick={() => handleData(type)}>
                      <i className={type.faicon}></i>
                      {type.name}
                    </span>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>

          <div className="tab-active-list">
            <div className="view">
              <img src="/assets/images/aiAssistant/code-icon.svg" />
              <h2>Coding</h2>
              Find a restaurant with a great happy for next wed for 6 people
            </div>
            <div className="view">
              <img src="/assets/images/aiAssistant/planning.svg" />
              <h2>Planning</h2>
              Find a sushi restaurant with high rating with reservations tonight
              for 2
            </div>
            <div className="view">
              <img src="/assets/images/aiAssistant/knowledge-icon.svg" />
              <h2>Knowledge</h2>
              Reserve a table for 8 at a seafood restaurant next friday at 6 PM
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AIAssistant;

import React, { useEffect, useState, useRef } from "react";
import LoginStore from "../../../../services/thirdPartyService";
import aiAssistant from "../../../../services/aiAssistant";

function AIAssistant() {
  const [state, setState] = useState({
    query: "",
    sessionData: "",
    response: false,
    messages: [],
  });
  const chatHistoryRef = useRef(null);

  const updateState = (obj) => {
    setState((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [state.messages]);

  const fetchData = async () => {
    let sessionData = await LoginStore.getLoginInfo({});
    updateState({ sessionData: sessionData });
  };

  const handleChange = (e) => {
    updateState({ query: e.target.value });
  };

  const formatTimestamp = (date) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const dateOptions = {
      month: "short",
      day: "2-digit",
    };
    const timeString = new Intl.DateTimeFormat("en-US", options).format(date);
    const dateString = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date
    );
    console.log(dateString, "dateString");

    return `${timeString}, ${dateString}`;
  };

  const handleClick = async () => {
    if (!state.query.trim()) return;
    const timestamp = formatTimestamp(new Date());

    // Add user message
    updateState({
      messages: [
        ...state.messages,
        {
          message: state.query,
          timestamp,
          type: "sender",
        },
      ],
      response: true,
      query: "",
    });

    let object = {
      query: state.query,
      transferee_uid: state.sessionData.userUniqueId,
    };

    const aiResponse = await aiAssistant.aiAssistantResponse(object);

    if (aiResponse) {
      updateState({
        messages: [
          ...state.messages,
          {
            message: state.query,
            timestamp,
            type: "sender",
          },
          {
            message: aiResponse,
            timestamp: formatTimestamp(new Date()),
            type: "receiver",
          },
        ],
        response: false,
      });
    }
  };

  return (
    <div
      className={
        state.messages.length > 0
          ? "relo-aiAssistant withChat"
          : "relo-aiAssistant "
      }
    >
      <h2>How can I help you?</h2>

      {/* Chat Container */}
      {state.messages.length > 0 && (
        <div className="chatHistory" ref={chatHistoryRef}>
          {state.messages.map((message, index) => (
            <div className={`chatView ${message.type}`} key={index}>
              <span>
                {message.type === "receiver" && (
                  <img src="assets/images/new-logo.svg" alt="" />
                )}
                {message.timestamp}
              </span>

              <p>{message.message}</p>
            </div>
          ))}
        </div>
      )}

      {/* Input and Submit Button */}
      <div className="textHere">
        <textarea
          value={state.query}
          onChange={(e) => handleChange(e)}
          rows="5"
          cols="40"
          placeholder="Type here..."
        />
        {!state.response && (
          <button onClick={handleClick} className="btn">
            <i className="fa-solid fa-arrow-up"></i>
          </button>
        )}
      </div>
    </div>
  );
}

export default AIAssistant;

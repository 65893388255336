import React, { useState, useEffect } from "react";
import CountryList from "country-list-with-dial-code-and-flag";
import "./IntTelDropDown.css";

const IntTelDropDown = ({ handleCountryCodeChange, countrycode }) => {
  const [selectedCountry, setSelectedCountry] = useState({});
  const [allCountriesData, setAllCountriesData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setIsOpen(!isOpen);
    handleCountryCodeChange(country.data.dial_code.substring(1));
  };

  function prepareCountriesData() {
    let allCountriesData = CountryList.getAll();
    // Filter out Canada (code: "CA")
    allCountriesData = allCountriesData.filter(
      (country) => country.data.code !== "CA"
    );
    setAllCountriesData(allCountriesData);
    return allCountriesData;
  }

  function isDialCodeSame(module_data, api_data) {
    // console.log(module_data, api_data);
    return module_data === "+" + api_data || module_data === api_data;
  }

  function findCountryByDialCode(dialCode, allCountriesData) {
    if (dialCode) {
      for (let i = 0; i < allCountriesData.length; i++) {
        if (isDialCodeSame(allCountriesData[i].data.dial_code, dialCode)) {
          return allCountriesData[i];
        }
      }
    } else {
      return {
        name: "United States",
        dial_code: "+1",
        code: "US",
        preferred: true,
        flag: "🇺🇸",
      };
    }
  }

  useEffect(() => {
    const allCountriesData = prepareCountriesData();
    // console.log(findCountryByDialCode(countrycode, allCountriesData));
    setSelectedCountry(findCountryByDialCode(countrycode, allCountriesData));
  }, [countrycode]);

  return (
    <div className="custom-dropdown">
      <div
        className={`dropdown-selected flag ${isOpen ? "open" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedCountry ? (
          <>
            {selectedCountry.flag}
            &nbsp; <i className="fa fa-caret-down"></i>
          </>
        ) : (
          "Select an option"
        )}
      </div>
      {isOpen && (
        <div className="dropdown-options">
          {allCountriesData.map((country, index) => (
            <div
              key={index}
              className="dropdown-option"
              onClick={() => handleCountryChange(country)}
            >
              {country.data.flag} {country.data.name} ({country.data.dial_code})
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default IntTelDropDown;

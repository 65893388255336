import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmojiPicker from "emoji-picker-react";

import {
  faExpand,
  faTimes,
  faPaperPlane,
  faChevronLeft,
} from "@fortawesome/fontawesome-free-solid";

import { message, Popover, Spin } from "antd";
import moment from "moment";
import axios from "axios";

import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";

// import * as signalR from "@aspnet/signalr/dist/browser/signalr.js";

var ChatProxy;
var SignalrConnection;

var sessionData = {};
var chatItineraryPropertyID = "0000111122223333";
var chatMessagesMap = new Map();
var responseTransfreeProperties = [];

const MessageCenterNew = () => {
  let displayone = { propertyname: "General" };
  const chatMsgsRef = useRef(null);

  const [state, setState] = useState({
    loader: false,
    sessionData: {},
    transfereeProperties: [],
    generalProperty: {
      id: "0000111122223333",
      apiId: "0000111122223333",
      propertyanalyticsuniqueId: "0000111122223333",
      property_image: "assets/images/default-img.jpg",
      address: "General",
      countUnread: 0,
    },
    countUnread: [],
    openChatboxPopup: true,
    openPropetyChatList: false,
    openChatBox: false,
    TotalUnreadCount: 0,
    onlineusers: [],
    chatItineraryPropertyID: "0000111122223333",
    responseTransfreeProperties: [],
    uploadImages: [],
    uploadImageError: "",
    color: "",
  });

  const [TextMessage, setTextMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messageInputRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const fileInputRef = useRef(null);
  const HubConnection = useRef(null);
  const Hub = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target) &&
        !event.target.classList.contains("bi-emoji-smile")
      ) {
        setShowEmojiPicker(false);
      }
    }

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function deepCopy(source) {
    return JSON.parse(JSON.stringify(source));
  }

  function changeLoaderState(mode) {
    if (mode === "start") {
      updateState({ loader: true });
    } else {
      updateState({ loader: false });
    }
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  async function getSessionData() {
    try {
      let sessionData = await LoginStore.getLoginInfo({});
      sessionData.subscriptioncode =
        localStorage.getItem("loginSubscribeDetails") != null
          ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
              .subscriptioncode
          : "";
      return sessionData;
    } catch (err) {
      console.log(err);
    }
  }

  function getUreadCount(AppId, TransfereeId) {
    let countUnread = state.countUnread.filter(function (o) {
      return (
        o.APISource == AppId &&
        o.FromId == sessionData.consultantUId &&
        o.ToId == TransfereeId
      );
    });
    return countUnread.length > 0 ? countUnread[0].Count : 0;
  }

  async function getData(transfereeProperties) {
    changeLoaderState("start");
    let ZillowBanglow = transfereeProperties.filter(function (element) {
      return element.source != "my_property34" && element.propertyname;
    });

    responseTransfreeProperties = deepCopy(ZillowBanglow);

    let generalCategory = { ...state.generalProperty };
    generalCategory.countUnread = getUreadCount(
      generalCategory.id,
      sessionData.userUniqueId
    );
    // sortResponseTransfereeProperties(chatMessagesMap);
    updateState({
      generalProperty: generalCategory,
      responseTransfreeProperties: ZillowBanglow,
    });
    changeLoaderState("stop");
  }

  async function getChatPropertiesList() {
    changeLoaderState("start");

    try {
      sessionData = await getSessionData();

      updateState({ sessionData: { ...sessionData } });

      let GetTransfereePropertiesObject = {
        transfereeuniqueId: sessionData.userUniqueId,
      };
      let transfereeProperties = await ApiServices.GetTransfereeProperties(
        GetTransfereePropertiesObject
      );
      let transfereepropertyList =
        transfereeProperties.message.transfereepropertyList;

      let myPropertiesList = transfereepropertyList.filter(function (element) {
        return (
          element.source != "zillow" &&
          element.isinterested == true &&
          element.isactive == true &&
          element.apiId != ""
        );
      });
      if (transfereeProperties.error == 0) {
        if (sessionData.subscriptioncode != "ReloNavigator") {
          updateState({ transfereeProperties: myPropertiesList });
        }
      }
      getData(myPropertiesList);
      // sortResponseTransfereeProperties(chatMessagesMap);
    } catch (err) {
      console.log(err);
    }
  }

  // --------------------------------

  function closeChatList() {}

  function componentDidMountLogic() {
    let subscriptioncode =
      localStorage.getItem("loginSubscribeDetails") != null
        ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
            .subscriptioncode
        : "";

    if (subscriptioncode != "ReloNavigator") {
      makeConnectionSignalR();
    }
  }

  async function logoutFromChat() {
    let changeSession = localStorage.getItem("ChatSession");

    if (changeSession != null && changeSession != undefined) {
      let responseText = await Hub.invoke("LogoutUserChatt", {
        userId: changeSession,
      });
      localStorage.removeItem("ChatSession");
    }
  }

  function changeConsultantOnlineStatus(isOnline) {
    const onlineStatus = isOnline ? "Currently Online" : "Currently Offline";
    updateState({ popoverText: onlineStatus });

    const userElement = $("#online" + sessionData.consultantUId);
    if (isOnline) {
      userElement.removeClass("offline");
      userElement.addClass("online");
    } else {
      userElement.removeClass("online");
      userElement.addClass("offline");
    }
  }

  function processLogoutUsersData(onlineusers) {
    console.log("Processing logout users");
    if (onlineusers && onlineusers.length) {
      localStorage.setItem("onlineusers", onlineusers);
      let onlineUserIndex = _.indexOf(onlineusers, sessionData.consultantUId);
      onlineUserIndex > -1
        ? changeConsultantOnlineStatus(true)
        : changeConsultantOnlineStatus(false);
    }
  }

  function isMessageForCurrentChat(row) {
    return (
      row.apiId == chatItineraryPropertyID &&
      row.toId == sessionData.userUniqueId
    );
  }

  function isMessageFromGeneralProperty(row) {
    return (
      row.apiId != chatItineraryPropertyID &&
      row.apiId == "0000111122223333" &&
      row.toId == sessionData.userUniqueId
    );
  }

  function updatePropertyWithCountUnread(row) {
    let updatedPropertiesList = increaseUreadCountForProperty(
      responseTransfreeProperties,
      row.apiId
    );

    updatedPropertiesList = movePropertyToTop(updatedPropertiesList, row.apiId);

    responseTransfreeProperties = deepCopy(updatedPropertiesList);
    updateState({
      TotalUnreadCount: state.TotalUnreadCount + 1,
      responseTransfreeProperties: updatedPropertiesList,
    });
  }

  function updateGeneralPropertyWithCountUnread() {
    let generalProperty = { ...state.generalProperty };

    generalProperty.countUnread = generalProperty.countUnread + 1;
    generalProperty.updatedDate = moment()
      .tz("America/Los_Angeles")
      .format("MM/DD/YYYY HH:mm:ss");

    updateState({
      generalProperty,
      TotalUnreadCount: state.TotalUnreadCount + 1,
    });
  }

  function handleMessageFromCurrentChat(row) {
    let updatedPropertiesList = movePropertyToTop(
      responseTransfreeProperties,
      row.apiId
    );

    responseTransfreeProperties = deepCopy(updatedPropertiesList);
    updateState({
      responseTransfreeProperties: updatedPropertiesList,
    });

    let ReadObj = {
      toId: sessionData.userUniqueId,
      fromId: sessionData.consultantUId,
      apiId: chatItineraryPropertyID,
    };
    Hub.current.invoke("MessageReadComplete", ReadObj);
  }

  function processArrayResponse(data) {
    data.map((row, index) => {
      updateChatMessagesMap(row.apiId, data);
      sortResponseTransfereeProperties(chatMessagesMap);
      if (!isMessageForCurrentChat(row) && isMessageFromGeneralProperty(row)) {
        console.log("message is not for current chat", data);
        updatePropertyWithCountUnread(row);
      } else if (isMessageFromGeneralProperty(row)) {
        console.log("message is for general property", data);
        updateGeneralPropertyWithCountUnread();
      } else {
        // message is from currently open chat
        console.log("message is for current chat", data);
        handleMessageFromCurrentChat(row);
      }
    });

    updateState({
      showChatMsg: true,
    });
  }

  function processObjectResponse(data) {
    // console.log(data, "processObject", responseTransfreeProperties);

    if (data.count) {
      updateState({ countUnread: data.count });
      updateNewCount(data.count);
    }

    if (data.messagesList) {
      updateState({ loader: false });
      chatMessagesMap = prepareMapFromMessagesList(data.messagesList);

      sortResponseTransfereeProperties(chatMessagesMap);
    }

    updateState({ showChatMsg: true });
  }

  function sortResponseTransfereeProperties(chatMessagesMap) {
    // console.log("hellosort");
    // Ensure responseTransfreeProperties is an array and contains elements
    if (
      !Array.isArray(responseTransfreeProperties) ||
      responseTransfreeProperties.length === 0
    )
      return;

    responseTransfreeProperties.sort((a, b) => {
      let a_apiId = a.propertyanalyticsuniqueId,
        b_apiId = b.propertyanalyticsuniqueId;

      let a_msgList = chatMessagesMap.get(a_apiId),
        b_msgList = chatMessagesMap.get(b_apiId);

      // Handle undefined or empty message lists
      let a_msgListLength = a_msgList?.length || 0,
        b_msgListLength = b_msgList?.length || 0;

      let a_lastMsgTime =
          a_msgListLength > 0 ? a_msgList[a_msgListLength - 1].datetime : null,
        b_lastMsgTime =
          b_msgListLength > 0 ? b_msgList[b_msgListLength - 1].datetime : null;

      // Handle cases where there is no valid datetime
      if (!a_lastMsgTime && !b_lastMsgTime) return 0;
      if (!a_lastMsgTime) return 1;
      if (!b_lastMsgTime) return -1;

      return new Date(b_lastMsgTime) - new Date(a_lastMsgTime);
    });
    // console.log("hellosort");

    // Deep copy if needed
    responseTransfreeProperties = deepCopy(responseTransfreeProperties);
    updateState({
      responseTransfreeProperties: responseTransfreeProperties,
    });
  }

  function processMessageFromServer(data) {
    console.log("Processing message from server", data);
    if (Array.isArray(data)) {
      console.log("Proceeding with array logic");
      // handle logic for the incomming message [{apiId: ,}]
      processArrayResponse(data);
    } else {
      console.log("Proceeding with object logic");

      processObjectResponse(data);
      processLogoutUsersData(data.onlineusers);
      updateState({ onlineusers: data.onlineusers });
    }

    scrollDivBottom();
  }

  async function makeConnectionSignalR() {
    // console.log(chatItineraryPropertyID, "chatItineraryPropertyID");
    var ChatServerUrl = "https://apis.relonavigator.com/";
    var ChatUrl = ChatServerUrl + "signalr";
    HubConnection.current = $.hubConnection(ChatUrl, {
      useDefaultPath: false,
    });

    Hub.current = HubConnection.current.createHubProxy("ChatHub");

    Hub.current.on("getLogoutUsersList", (data) =>
      processLogoutUsersData(data.onlineusers)
    );

    Hub.current.on("messageFromServer", processMessageFromServer.bind(this));

    HubConnection.current
      .start()
      .done(function (data) {
        if (true || _this._isMounted) {
          updateState({ chatIdSignalR: data.id });
        }
        var objC = {};
        objC.userId = sessionData.userUniqueId;
        objC.apiId = chatItineraryPropertyID;
        objC.connectionId = data.id;
        objC.isconsultent = false;
        let changeSession = localStorage.getItem("ChatSession");
        if (changeSession == null || changeSession == undefined) {
          Hub.current.invoke("RegisterOnlineUser", objC);
          Hub.current.invoke("LogoutUserChatt", { userId: "" });
        }
        openChatBox(
          state.generalProperty.propertyanalyticsuniqueId,
          state.generalProperty.property_image,
          displayone
        );
        logoutFromChat();
      })
      .fail(function () {
        console.log("failed in connecting to the signalr server");
      });

    // changeLoaderState("stop");
  }

  function getSignalRConnection() {
    HubConnection.current
      .start()
      .done(function (data) {
        updateState({ chatIdSignalR: data.id });
        var objC = {};
        objC.userId = sessionData.userUniqueId;
        objC.apiId = 0;
        objC.connectionId = data.id;
        objC.isconsultent = false;

        Hub.current.invoke("RegisterOnlineUser", objC);
      })
      .fail(function () {
        console.log("failed in connecting to the signalr server");
      });
  }

  function openChatBox(Id, property_image_pic, row) {
    // console.log(Id, property_image_pic, row, "Id, property_image_pic, row");

    let property_image_img = property_image_pic
      ? property_image_pic.split(",")[0]
      : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png";

    updateState({
      displayname: row.propertyname,
      showChatMsg: false,
      profilePic: property_image_img,
      isPropertySelected: true,
      chatItineraryPropertyID: Id,
      ChatProperty: "",
      openChatBox: true,
      openPropetyChatList: false,
    });

    clearBadgeOnSeeingMessages(Id);

    chatItineraryPropertyID = Id;
    // console.log(Id, "chatItineraryPropertyID");

    let ReadObj = {
      toId: sessionData.userUniqueId,
      fromId: sessionData.consultantUId,
      apiId: Id,
    };
    Hub.current.invoke("MessageReadComplete", ReadObj);
    getSignalRConnection();

    $("#left1").addClass("chat-left-md-view");
    $("#right1").removeClass("chat-right-md-view");

    let TotalUnreadCountOriginal = state.TotalUnreadCount;
    if (Id == "0000111122223333") {
      TotalUnreadCountOriginal =
        TotalUnreadCountOriginal - state.generalProperty.countUnread;
      updateState({ TotalUnreadCount: TotalUnreadCountOriginal });
    } else {
      let filterObject = _.findIndex(responseTransfreeProperties, function (o) {
        return o.propertyanalyticsuniqueId == Id;
      });

      TotalUnreadCountOriginal =
        TotalUnreadCountOriginal -
        responseTransfreeProperties[filterObject].countUnread;

      responseTransfreeProperties[filterObject].countUnread = 0;
      updateState({
        TotalUnreadCount: TotalUnreadCountOriginal,
        responseTransfreeProperties: responseTransfreeProperties,
      });
    }
  }

  function scrollDivBottom() {
    var objDiv = $("#chatPopup").height();
    $("#chatPopup").scrollTop(objDiv * 100);

    var objDiv2 = $("#chat_msgs").height();
    $("#chat_msgs").scrollTop(objDiv2 * 100);
  }

  function updateNewCount(count) {
    try {
      // Filter count data where ToId matches sessionData.userUniqueId
      const filteredCount = count.filter(
        (item) => item.ToId === sessionData.userUniqueId
      );

      if (filteredCount.length === 0) {
        console.log("No matching ToId found for the current session.");
        return; // Exit if no matching items are found
      }

      // Create a mapping of APISource to Count for easier lookup
      const countMap = filteredCount.reduce((acc, item) => {
        acc[item.APISource] = item.Count; // Map APISource to its Count
        return acc;
      }, {});
      // console.log(countMap, "countMap");

      // Check if the specific generalProperty ID exists in the countMap
      if (countMap["0000111122223333"] !== undefined) {
        console.log();

        let generalProperty = { ...state.generalProperty };

        // Update generalProperty with new count and date
        generalProperty.countUnread = countMap["0000111122223333"];
        generalProperty.updatedDate = moment()
          .tz("America/Los_Angeles")
          .format("MM/DD/YYYY HH:mm:ss");

        // Update state with new generalProperty and increment TotalUnreadCount
        setState((prevState) => ({
          ...prevState,
          generalProperty,
          TotalUnreadCount: (prevState.TotalUnreadCount || 0) + 1,
        }));
      }

      responseTransfreeProperties = responseTransfreeProperties
        .map((property) => {
          if (countMap[property.propertyanalyticsuniqueId] !== undefined) {
            return {
              ...property,
              countUnread: countMap[property.propertyanalyticsuniqueId],
            };
          }
          return property;
        })
        .sort((a, b) => (b.countUnread || 0) - (a.countUnread || 0));

      // console.log(responseTransfreeProperties, "responseTransfreeProperties");

      responseTransfreeProperties = deepCopy(responseTransfreeProperties);
      updateState({ responseTransfreeProperties: responseTransfreeProperties });
    } catch (error) {}
  }

  function clearBadgeOnSeeingMessages(Id) {
    const updatedPropertiesList = state.responseTransfreeProperties.map(
      (property) => {
        if (property.propertyanalyticsuniqueId === Id) {
          return {
            ...property,
            countUnread: 0,
          };
        }
        return property;
      }
    );

    responseTransfreeProperties = deepCopy(updatedPropertiesList);
    updateState({
      responseTransfreeProperties: updatedPropertiesList,
    });
  }

  function messageText(event) {
    event.persist();
    let textMessage = event.target.value;
    setTextMessage(textMessage);
  }

  const toggleEmojiPicker = () => {
    console.log("hello");

    setShowEmojiPicker((prev) => !prev);
  };

  const handleEmojiClick = (emojiObject) => {
    setTextMessage((prev) => prev + emojiObject.emoji); // Append emoji
    messageInputRef.current.focus(); // Focus back on the input field
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    console.log(file, "file");

    const formatValid = isFileFormatSupported(event);

    if (formatValid.length > 0) {
      uploadImages(formatValid);
    }
  };

  function isFileFormatSupported(event) {
    const types = ["image/png", "image/jpeg", "image/gif"];
    const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
    let files = event.target.files;
    let validFiles = [];
    let errorMessages = [];

    for (let i = 0; i < files.length; i++) {
      if (!types.includes(files[i].type)) {
        errorMessages.push(`${files[i].name}: Unsupported file format.`);
      } else if (files[i].size > maxFileSize) {
        errorMessages.push(`${files[i].name}: File size exceeds 1MB.`);
      } else {
        // Add valid files to the array
        validFiles.push(files[i]);
      }
    }

    // If there are error messages, update the state with the errors
    if (errorMessages.length > 0) {
      updateState({
        uploadImageError: errorMessages.join(" "),
        color: "red",
      });
    }

    // Return the valid files for further processing
    return validFiles;
  }

  function uploadImages(correctfiles) {
    let files = correctfiles;
    // console.log(files, "files uploaded ");

    const data = new FormData();
    for (var x = 0; x < files.length; x++) {
      data.append("file", files[x]);
    }
    updateState({ uploadImageError: "", loader: true });
    try {
      axios
        .post(`/profileimagesInfo`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let data = [...state.uploadImages];
          _.forEach(response.data, async (response) => {
            data.push(response);
          });
          console.log(data, "data images");

          updateState({
            uploadImages: data,
            uploadImageError: "Successfully uploaded.",
            color: "green",
            loader: false,
          });
        })
        .catch((error) => {
          console.log(error, "error");

          setState({
            uploadImageError: "something went wrong while uploading images",
            loader: false,
            color: "red",
          });
        });
    } catch (error) {
      console.log(error);
      setState({
        uploadImageError: "something went wrong while uploading images",
        loader: false,
        color: "red",
      });
    }
  }

  const removeimage = async (value, id) => {
    let list = Object.assign([], state.uploadImages);
    let arr = list.filter((item) => item !== value);
    await setState((prevState) => ({
      ...prevState,
      uploadImages: arr,
      uploadImageError: "",
    }));
  };

  function sendMessage(event) {
    event.preventDefault();
    let sendMessageObj = {
      apiId: chatItineraryPropertyID,
      message: TextMessage.trim(),
      // message:
      //   TextMessage.trim() +
      //   (state.uploadImages && state.uploadImages.length > 0
      //     ? state.uploadImages
      //         .map(
      //           (image, index) =>
      //             `<img src="${state.uploadImages[index].Location}" alt="Selected Image" style="margin:10px" />`
      //         )
      //         .join("") // Join the images if there are multiple
      //     : ""),
      fromId: sessionData.userUniqueId,
      toId: sessionData.consultantUId,
      datetime: moment()
        .tz("America/Los_Angeles")
        .format("MM/DD/YYYY HH:mm:ss"),
      isconsultant: false,
    };

    if (TextMessage.trim() !== "") {
      let updatedPropertiesList = movePropertyToTop(
        state.responseTransfreeProperties,
        chatItineraryPropertyID
      );
      // console.log(sendMessageObj, "sendMessageObj");

      // responseTransfreeProperties = deepCopy(updatedPropertiesList);

      // updateState({
      //   responseTransfreeProperties: updatedPropertiesList,
      // });
      updateChatMessagesMap(chatItineraryPropertyID, sendMessageObj);
      sortResponseTransfereeProperties(chatMessagesMap);

      Hub.current.invoke("SendMessage", sendMessageObj);

      scrollDivBottom();
      $("#InputMsg").val("");
      $("#InputMsg2").val("");
      $("#InputMsg2").focus();

      setTextMessage("");
      setShowEmojiPicker(false);
      updateState({
        uploadImageError: "",
        uploadImages: [],
      });

      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
    }
  }

  function movePropertyToTop(propertiesList, propertyUniqueId) {
    let propertyWithUnreadMsg = propertiesList.filter(
      (property) => property.propertyanalyticsuniqueId === propertyUniqueId
    );

    let otherProperties = propertiesList.filter(
      (property) => property.propertyanalyticsuniqueId !== propertyUniqueId
    );

    return [...propertyWithUnreadMsg, ...otherProperties];
  }

  function increaseUreadCountForProperty(propertiesList, propertyUniqueId) {
    return propertiesList.map((property) => {
      if (property.propertyanalyticsuniqueId === propertyUniqueId) {
        return {
          ...property,
          countUnread: property.countUnread ? property.countUnread + 1 : 1,
        };
      }
      return property;
    });
  }

  function prepareMapFromMessagesList(messages) {
    const groupedMessages = messages.reduce((grouped, message) => {
      const { apiId } = message;
      if (!grouped.has(apiId)) {
        grouped.set(apiId, []);
      }
      grouped.get(apiId).push(message);
      return grouped;
    }, new Map());

    // Sort each group by datetime
    groupedMessages.forEach((messages) => {
      messages.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
    });

    return groupedMessages;
  }

  function updateChatMessagesMap(apiId, sendMessageObj) {
    let propertyMessagesList = chatMessagesMap.get(apiId) || [];

    if (Array.isArray(sendMessageObj)) {
      propertyMessagesList.push(sendMessageObj[0]);
    } else {
      propertyMessagesList.push(sendMessageObj);
    }
    chatMessagesMap.set(apiId, propertyMessagesList);
  }

  function searchProperty(value) {
    const propertyList = responseTransfreeProperties;
    let list = state.responseTransfreeProperties;
    if (value != "") {
      const filterProperty = propertyList.filter(
        (o) =>
          // Object.keys(o).some((k) =>
          //   String(o[k]).toLowerCase().includes(value.toLowerCase())
          o.propertyname.toLowerCase().includes(value.toLowerCase()) ||
          o.address.toLowerCase().includes(value.toLowerCase())
      );
      console.log(filterProperty, "filterProperty", propertyList);

      updateState({
        responseTransfreeProperties: filterProperty,
      });
    } else {
      console.log("no value", propertyList);
      updateState({
        responseTransfreeProperties: propertyList,
      });
    }
  }

  function backChatBox() {
    $("#left1").removeClass("chat-left-md-view");
    $("#right1").addClass("chat-right-md-view");
  }

  useEffect(() => {
    getChatPropertiesList();
    // componentDidMountLogic();
  }, []);
  useEffect(() => {
    if (
      (state.responseTransfreeProperties.length == 0 ||
        state.responseTransfreeProperties.length > 0) &&
      !HubConnection.current
    ) {
      componentDidMountLogic();
    }
  }, [state.responseTransfreeProperties]);

  // useEffect(() => {
  //   if (state.sessionData.consultantUId && state.onlineusers?.length) {
  //     processLogoutUsersData(state.onlineusers);
  //   }
  // }, [state.sessionData.consultantUId, state.onlineusers]);

  // useEffect(() => {
  //   if (
  //     responseTransfreeProperties.length &&
  //     chatMessagesMap.size &&
  //     responseTransfreeProperties.length <= chatMessagesMap.size
  //   ) {
  //     console.log("useEffect");

  //     sortResponseTransfereeProperties(chatMessagesMap);
  //   }
  // }, [chatMessagesMap]);

  return (
    <div className="b-radius-8 chat-main-div mt-3">
      {state.loader && <div className="loading">Loading</div>}

      <div className="chat_footer chat_footer-fullview">
        {state.openChatboxPopup == true ? (
          <div className="communitie_pop">
            {/* <div className="communities_header d-flex justify-content-between">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeChatList.bind(this)}
              >
                <span aria-hidden="true">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </button>
            </div> */}

            <div className="d-flex d-flex-chat">
              {/* left container */}
              <div className="item properties" id="left1">
                <h5>Message center</h5>

                <div class="input-group mb-3">
                  <span class="input-group-text">
                    <i class="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Propertyname"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => searchProperty(e.target.value)}
                  />
                </div>

                <div className="contacts_list">
                  {/* messages ---general tab---start */}
                  <div
                    key={1000}
                    className={
                      // "chat_item d-flex align-items-center justify-content-start "
                      chatItineraryPropertyID ==
                      state.generalProperty.propertyanalyticsuniqueId
                        ? "chat_item d-flex align-items-center justify-content-start activeItem"
                        : "chat_item d-flex align-items-center justify-content-start"
                    }
                    id={
                      "highlight" +
                      state.generalProperty.propertyanalyticsuniqueId
                    }
                    onClick={() =>
                      openChatBox(
                        state.generalProperty.propertyanalyticsuniqueId,
                        state.generalProperty.property_image,
                        displayone
                      )
                    }
                  >
                    <div className="item_img bd-highlight">
                      <i className="bi bi-check-circle-fill"></i>
                      <img src={state.generalProperty.property_image} alt="" />
                      <div
                        className={
                          "msg_count" +
                          (state.generalProperty.countUnread > 0
                            ? ""
                            : " hideBadge")
                        }
                        id={
                          "bw" + state.generalProperty.propertyanalyticsuniqueId
                        }
                      >
                        <span
                          className="badge"
                          id={
                            "bbd" +
                            state.generalProperty.propertyanalyticsuniqueId
                          }
                        >
                          {state.generalProperty.countUnread}
                        </span>
                      </div>
                    </div>

                    <div className="chat_dtls">
                      <span>{state.generalProperty.address}</span>
                    </div>
                  </div>
                  {/* messages ---general tab--- end */}

                  {/* messages ---individual tabs ---start*/}
                  {state.responseTransfreeProperties.length == 0 ? (
                    <div style={{ padding: "10px" }}>
                      <Spin />
                    </div>
                  ) : (
                    ""
                  )}
                  {state.responseTransfreeProperties.map((row, index) => {
                    return (
                      <IndividualPropertyTab
                        openChatBox={openChatBox}
                        state={state}
                        row={row}
                        key={index}
                      />
                    );
                  })}
                  {/* messages --- individual tabs --end */}
                </div>
              </div>

              {/* right container */}
              <div id="right1" className="item ">
                {/* <div
                  onClick={() => backChatBox()}
                  className="chat-heading-view"
                >
                  <img src="../assets/images/back-arrow.svg" alt="" />
                  <span>{state.displayname}</span>
                </div> */}

                <div className="contactDP">
                  <img
                    src={
                      sessionData.consultantProfileImage != "" &&
                      sessionData.consultantProfileImage != null
                        ? sessionData.consultantProfileImage
                        : "../assets/images/profile-pic.svg"
                    }
                    alt=""
                  />{" "}
                  <h5>
                    {sessionData.consultantname}

                    <div
                      className="circle offline"
                      id={"online" + sessionData.consultantUId}
                    >
                      {" "}
                      Online{" "}
                    </div>
                    {/* </Popover> */}
                  </h5>
                  {/* <i className="bi bi-three-dots-vertical"></i> */}
                </div>

                {state.isPropertySelected == true ? (
                  <div>
                    {/* chat messages history */}
                    <div className="chat_msgs" id="chatPopup" ref={chatMsgsRef}>
                      {chatMessagesMap.size &&
                        chatMessagesMap
                          .get(chatItineraryPropertyID)
                          ?.map((row, index) => {
                            // const isHTML = /<\/?[a-z][\s\S]*>/i.test(
                            //   row.message
                            // );
                            return (
                              <div key={index}>
                                {sessionData != "" &&
                                sessionData.userUniqueId == row.fromId ? (
                                  <div className="right_chat_view ">
                                    <div className="me-3">
                                      <div className="right-user-time">
                                        {moment(row.datetime).calendar()}
                                      </div>

                                      <div className="right_chat">
                                        {/* {isHTML ? (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: row.message,
                                            }}
                                            className="chat-image-container"
                                          />
                                        ) : ( */}
                                        <span>{row.message}</span>
                                        {/* )} */}
                                        <i className="bi bi-check-all ms-2"></i>
                                      </div>
                                    </div>
                                    {/* <img
                                      src="../assets/images/profilePic.png"
                                      alt=""
                                    /> */}
                                  </div>
                                ) : (
                                  <div className="left_chat_view ">
                                    <div className="left-image">
                                      {/* <img
                                        src={
                                          sessionData.consultantprofilepic !=
                                            "" &&
                                          sessionData.consultantprofilepic !=
                                            null
                                            ? sessionData.consultantprofilepic
                                            : "../assets/images/profilePic.png"
                                        }
                                        alt=""
                                      /> */}
                                    </div>
                                    <div>
                                      <div className="left-user-time">
                                        {row.firstname} {row.lastname},{" "}
                                        {moment(row.datetime).calendar()}
                                      </div>
                                      <div key={index} className="left_chat">
                                        {/* {isHTML ? (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: row.message,
                                            }}
                                            className="chat-image-container"
                                          />
                                        ) : ( */}
                                        <span>{row.message}</span>
                                        {/* )} */}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}

                      {state.showChatMsg == false ? (
                        <div style={{ paddingTop: "100px" }}>
                          <Spin />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="clearfix"></div>
                    </div>

                    {/* chat message send box */}
                    <div className="sent_box d-flex align-items-center">
                      <div className="position-relative w-100">
                        <i
                          class="bi bi-emoji-smile"
                          // onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          onClick={toggleEmojiPicker}
                          style={{ cursor: "pointer" }}
                        ></i>
                        {showEmojiPicker && (
                          <div
                            ref={emojiPickerRef}
                            style={{
                              position: "absolute",
                              bottom: "50px",
                              left: "0",
                              zIndex: 10,
                            }}
                          >
                            <EmojiPicker onEmojiClick={handleEmojiClick} />
                          </div>
                        )}
                        <input
                          ref={messageInputRef}
                          tabIndex="1"
                          type="text"
                          id="InputMsg2"
                          value={TextMessage}
                          placeholder="Send message"
                          // onChange={messageText}
                          onChange={(e) => setTextMessage(e.target.value)}
                          onKeyDown={(e) => {
                            // Check if the Enter key (key code 13) is pressed
                            if (
                              e.key === "Enter" &&
                              TextMessage.trim() !== ""
                            ) {
                              sendMessage(e); // Call the send message function
                            }
                          }}
                        />
                        {/* <i
                          class="bi bi-plus-circle"
                          onClick={() => fileInputRef.current.click()}
                        ></i>
                        <input
                          ref={fileInputRef}
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={handleFileSelect}
                        /> */}
                      </div>

                      <span
                        tabIndex="2"
                        className={TextMessage != "" ? "" : "chatIconColor"}
                        onClick={(e) => sendMessage(e)}
                      >
                        {/* <FontAwesomeIcon icon={faPaperPlane} /> */}
                        <img src="../assets/images/Vector.svg" />
                      </span>
                    </div>
                    <span style={{ color: `${state.color}` }}>
                      {state.uploadImageError}
                    </span>
                    <div className="d-flex p-2">
                      {state.uploadImages &&
                        state.uploadImages.map((row, index) => {
                          return (
                            <div key={index} className="custom-file-upimg">
                              <img
                                src={`${state.uploadImages[index].Location}`}
                                alt={`Image ${index}`}
                              />
                              <i
                                className="fa fa-trash"
                                onClick={() => removeimage(row, index)}
                              ></i>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const IndividualPropertyTab = ({ openChatBox, state, row }) => {
  return (
    <div
      className={
        chatItineraryPropertyID == row.propertyanalyticsuniqueId
          ? "chat_item d-flex align-items-center justify-content-start activeItem"
          : "chat_item d-flex align-items-center justify-content-start"
      }
      id={
        "highlight" +
        (row.id != undefined ? row.id : row.propertyanalyticsuniqueId)
      }
      onClick={() =>
        openChatBox(
          row.id != undefined ? row.id : row.propertyanalyticsuniqueId,
          row.property_image,
          row
        )
      }
    >
      <div className="item_img bd-highlight">
        <i className="bi bi-check-circle-fill"></i>
        <img
          src={
            row.property_image
              ? row.property_image.split(",")[0]
              : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
          }
          alt=""
        />
        <div
          className={"msg_count" + (row.countUnread > 0 ? "" : " hideBadge")}
          id={
            "bw" +
            (row.id != undefined ? row.id : row.propertyanalyticsuniqueId)
          }
        >
          <span
            className="badge"
            id={
              "bbd" +
              (row.id != undefined ? row.id : row.propertyanalyticsuniqueId)
            }
          >
            {row.countUnread}
          </span>
        </div>
      </div>

      <div className="chat_dtls">
        <span>
          {row.propertyname} - {row.address}
          {/* <span className="dataTime"> 25 Aug </span> */}
        </span>
      </div>
    </div>
  );
};

export default MessageCenterNew;

import React, { useEffect, useState } from "react";
import { Select } from "antd";
import jData from "./dataFoursquare.json";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import AppController from "../controllers/appController";
import { Empty, Popover } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getGooglePlacesResults } from "./utils";
import GoogleMapsPlaces from "../../services/mapsPlaces";
import OwlCarousel from "react-owl-carousel";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import fourSquarePlaces from "../../services/fourSquarePlaces";

mapboxgl.accessToken =
  "sk.eyJ1IjoidGVqYWt1bWFyNjIiLCJhIjoiY2x4b2I2M3phMDZ5cDJqcHMxZnVpMjB3bCJ9.uscOlHG0Tdfk3s2LEGX98w";

function NewNearByPlaces(props) {
  // console.log(props, "props");
  const [newState, setNewState] = useState({
    mapLatitude: props.latitude,
    mapLongitude: props.longitude,
    latitude: props.latitude,
    longitude: props.longitude,
    selectedCategory: "",
    mapRes: [],
    newtypes: [
      {
        name: "Restaurants",
        type: "restaurant",
        faicon: "fa fa-cutlery",
        icon: "../assets/images/restaurant-new.svg",
      },
      {
        name: "Groceries",
        type: "supermarket",
        faicon: "fa fa-shopping-bag",
        icon: "../assets/images/grocery-1.svg",
      },
      {
        name: "Parks",
        type: "park",
        faicon: "fa fa-tree",
        icon: "../assets/images/park-1.svg",
      },
      {
        name: "Shopping",
        type: "shopping_mall",
        faicon: "fa fa-shopping-cart",
        icon: "../assets/images/shopping-3.svg",
      },
      {
        name: "Entertainment",
        type: "night_club",
        faicon: "fa fa-film",
        icon: "../assets/images/entertainment-1.svg",
      },
      {
        name: "Hospitals",
        type: "hospital",
        faicon: "fa fa-h-square",
        icon: "../assets/images/hos-1.svg",
      },
    ],
    type: "restaurant",
    typeName: ["Restaurants"],
    location: [],
    placeSearched: [],
    placeSearchedMarkers: [],
    markerList: [],
    showlist: false,
    showSearch: false,
    typeicon: 0,
    directionsService: {},
    directionsDisplay: {},
    communityName: "",
    restaurantsArr: [],
    restData: [],
    cityLanding: false,
    Loader: false,
    items: [],
    startPosition: "0",
    categories: [],
    RestaurantsMainArray: [],
    restaurantsList: [],
    typesList: [],
    RestauType: [],
    removeBoundary: false,
    mapText: "",
    activeNumber: -1,
    restaurantsRendered: 0,
    restlist: [],
    restlight: "",
    data: {},
    restlistarray: [],
    NearPlaces: [],
    activeMaker: null,
    activeNumber: -1,
    propertyInfo: false,
    propertyDetails: false,
    isProperty: false,
    propertyData: false,
    propertyName: "",
    propertyAddress: "",
    propertyIndex: "",
    propertyLatitude: "",
    propertyLongitude: "",
    singlePropertyData: {
      latitude: "",
      longitude: "",
      propertyAddress: "",
      propertyName: "",
    },
    singleProperty: false,
    updatedTypes: [],
    totalData: [],
    typesArray: [],
  });
  const [map, setMap] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);
  const [currentMarker, setCurrentMarker] = useState(null);
  const navigate = useNavigate();
  // console.log(state, "state");

  useEffect(() => {
    async function fetchData() {
      //   console.log(props, "useEffectprops");
      setNewState((prev) => ({
        ...prev,
        // latitude: props.latitude,
        // longitude: props.longitude,
        mapLatitude: props.latitude,
        mapLongitude: props.longitude,
        showlist: false,
        communityName: props.communityName,
        data: props.data,
        Loader: true,
        NearPlaces: "",
        isProperty: false,
        propertyAddress: "",
        propertyName: "",
        propertyIndex: "",
        propertyLatitude: "",
        propertyLongitude: "",
        type: "restaurant",
        typeName: "Restaurants",
      }));
      tab();
      let markers = [];
      let locationdata = {
        latitude: props.latitude,
        longitude: props.longitude,
        singleProperty: newState.singleProperty,
      };
      if (props.compareMap) {
        insertnewMap(markers, locationdata, newState.typesArray);
      }
    }
    fetchData();
  }, [props]);

  const insertnewMap = (markersData, locationdata, typesArray) => {
    // console.log(markersData, locationdata, "insertnewMap");

    const mapInstance = new mapboxgl.Map({
      container: "map1",
      style: "mapbox://styles/examples/clg45vm7400c501pfubolb0xz",
      center: [locationdata.longitude, locationdata.latitude], // Center map on the property // Initial map center [lng, lat]
      zoom: 13, // Initial map zoom level
    });
    // let markersData = mapState.poi;
    setMap(mapInstance);

    const nav = new mapboxgl.NavigationControl({
      showZoom: true,
      // showCompass: true,
    });
    mapInstance.addControl(nav, "bottom-left");

    // Initialize Mapbox Draw
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      styles: [
        // Style for the drawn lines (e.g., black color)
        {
          id: "gl-draw-line",
          type: "line",
          filter: ["==", "$type", "LineString"],
          paint: {
            "line-color": "#000000", // Black color for lines
            "line-width": 2, // Adjust the line width
          },
        },
        // Style for the drawn polygons (e.g., black fill)
        {
          id: "gl-draw-polygon-fill",
          type: "fill",
          filter: ["==", "$type", "Polygon"],
          paint: {
            "fill-color": "#000000", // Black color for fill
            "fill-opacity": 0.3, // Adjust opacity if desired
          },
        },
        // Style for polygon outlines (black color)
        {
          id: "gl-draw-polygon-stroke",
          type: "line",
          filter: ["==", "$type", "Polygon"],
          paint: {
            "line-color": "#000000", // Black color for polygon stroke
            "line-width": 2, // Adjust stroke width
          },
        },
        // Style for the drawn points (if you're using points too)
        {
          id: "gl-draw-point",
          type: "circle",
          filter: ["==", "$type", "Point"],
          paint: {
            "circle-radius": 5, // Adjust radius
            "circle-color": "#000000", // Black color for points
          },
        },
      ],
      defaultMode: "simple_select",
    });
    mapInstance.addControl(draw);

    let allMarkers = []; // Array to store all markers
    let drawnShapes = []; // Store drawn shapes
    const defaultMarkersData = markersData;
    let newMarkers = [];

    // Convert markersData into GeoJSON format
    const geojson = {
      type: "FeatureCollection",
      features: markersData.map((data) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            data.geocodes.main.longitude,
            data.geocodes.main.latitude,
          ],
        },
        properties: {
          name: data.name,
          lat: data.geocodes.main.latitude,
          lng: data.geocodes.main.longitude,
          vicinity: data.location.formatted_address.toString(),
          place_id: data.fsq_id,
          typeName: data.selectedName, // Add typeName to properties
        },
      })),
    };
    allMarkers = geojson;
    // console.log(geojson, "geojson");

    // Add a 'load' event listener to confirm the map has loaded
    setTimeout(() => {
      setNewState((prev) => ({ ...prev, Loader: false }));
      mapInstance.resize(); // Trigger resize with a slight delay
    }, 100);
    // Load images for all custom markers
    loadCustomMarkerImages(mapInstance);

    // Add the data source with clustering enabled
    mapInstance.on("load", () => {
      mapInstance.addSource("markers", {
        type: "geojson",
        data: geojson,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom level to cluster points
        clusterRadius: 50, // Cluster radius in pixels
      });

      // Add the cluster circles to the map
      mapInstance.addLayer({
        id: "clusters",
        type: "circle",
        source: "markers",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#000000", // color for small clusters
            100,
            "#000000", // color for medium clusters
            750,
            "#000000", // color for large clusters
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20, // radius for small clusters
            100,
            30, // radius for medium clusters
            750,
            40, // radius for large clusters
          ],
        },
      });

      // Add the cluster count label
      mapInstance.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "markers",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-size": 15,
        },
        paint: {
          "text-color": "#FFFFFF", // Set text color to white
        },
      });

      // Add unclustered points as markers with custom icons
      mapInstance.addLayer({
        id: "unclustered-point",
        type: "symbol",
        source: "markers",
        filter: ["!", ["has", "point_count"]],
        layout: {
          "icon-image": ["concat", "custom-marker-", ["get", "typeName"]], // Dynamically get the correct image based on typeName
          "icon-size": 1.0,
        },
      });

      // When a cluster is clicked, zoom into the cluster
      mapInstance.on("click", "clusters", (e) => {
        const features = mapInstance.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        mapInstance
          .getSource("markers")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            mapInstance.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      // Show popup for unclustered points
      mapInstance.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { name, vicinity, place_id, lat, lng } = e.features[0].properties;

        const popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(coordinates)
          .setHTML(
            `
            <h6>${name}</h6>
            <p>${vicinity}</p>
            <a href="https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}" target="_blank">Get Directions</a>
          `
          )
          .addTo(mapInstance);
        setCurrentPopup(popup);
        setNewState((prev) => ({
          ...prev,
          // activeMaker: data,
          activeNumber: place_id,
          latitude: coordinates[1],
          longitude: coordinates[0],
        }));
      });

      // Change the cursor to pointer when hovering over a cluster
      mapInstance.on("mouseenter", "clusters", () => {
        mapInstance.getCanvas().style.cursor = "pointer";
      });

      mapInstance.on("mouseleave", "clusters", () => {
        mapInstance.getCanvas().style.cursor = "";
      });

      mapInstance.on("draw.create", async (event) => {
        const shape = event.features[0];
        drawnShapes.push(shape); // Add new shape to the array
        const coordinates = shape.geometry.coordinates[0];
        const coordinatesString = `${coordinates
          .map((coord) => `${coord[1]},${coord[0]}`) // Format: "latitude,longitude"
          .join("~")}`;

        let dataaaa = [
          { key: "Restaurants", id: "4bf58dd8d48988d14c941735" },
          { key: "Groceries", id: "4bf58dd8d48988d118951735" },
          { key: "Parks", id: "4bf58dd8d48988d163941735" },
          { key: "Shopping", id: "4bf58dd8d48988d1fd941735" },
          { key: "Entertainment", id: "4d4b7104d754a06370d81259" },
          { key: "Hospitals", id: "4bf58dd8d48988d196941735" },
        ];
        // let obj = dataaaa.find((o) => o.key == typeName.toString());
        // console.log(obj, "objobjobj", typeName);

        // let apiObject = {
        //   polygon: coordinatesString,
        //   categories: obj.id,
        // };

        // const newAPiCall = await fourSquarePlaces.getFourSquarePlaces(
        //   apiObject
        // );

        const fetchPromises = typesArray.map(async (type) => {
          // Create a new apiObject for each type
          let apiObjectNew = {
            polygon: coordinatesString,
            categories: type,
          };

          // console.log(apiObjectNew, "apiObject for type:", type);

          // Make the API call
          const response = await fourSquarePlaces.getFourSquarePlaces(
            apiObjectNew
          );
          // console.log(response, "response");

          // Check if response has results and attach typeId
          if (response) {
            return response.map((item) => {
              const { fsq_id, geocodes, location, name, rating } = item;
              // Find the matching category from `dataaaa` based on `typeId`
              const category = dataaaa.find((cat) => cat.id === type);
              const selectedName = category ? category.key : "Unknown";
              return {
                fsq_id,
                geocodes,
                location,
                name,
                rating,
                selectedName, // Add `selectedName` key with the value from the category
              };
            });
          }
          return [];
        });

        // Wait for all promises to resolve and flatten the results
        const results = await Promise.all(fetchPromises);
        // console.log("Combined results:", results.flat());
        let newAPiCall = results.flat();
        // console.log(newAPiCall, "newAPiCall");

        addNewMarkersToMap(newAPiCall, mapInstance, shape);
        draw.changeMode("simple_select");
        // console.log(newMarkers, "newMarkers", flattenedData);
      });

      mapInstance.on("draw.delete", (event) => {
        const deletedFeatures = event.features; // Get deleted shapes
        const existingMarkers = mapInstance.getSource("markers")._data; // Get existing markers data

        // Create a new array to hold the markers that will be kept
        const markersToKeep = existingMarkers.features.filter((data) => {
          const markerPosition = [
            data.geometry.coordinates[0],
            data.geometry.coordinates[1],
          ];

          // Check if the marker is within any of the deleted shapes
          return !deletedFeatures.some((deletedShape) => {
            if (deletedShape.geometry.type === "Polygon") {
              return turf.booleanPointInPolygon(
                turf.point(markerPosition),
                turf.polygon(deletedShape.geometry.coordinates)
              );
            }
            return false; // Non-polygon shapes shouldn't be processed
          });
        });
        // console.log(markersToKeep, "markersToKeep");
        // Update existingMarkers to only include markers that are not deleted
        existingMarkers.features = markersToKeep;

        // Update the markers source with the remaining markers
        mapInstance.getSource("markers").setData(existingMarkers);

        // Remove deleted shapes from the localdrawnShapes array
        drawnShapes = drawnShapes.filter(
          (shape) => !deletedFeatures.some((deleted) => deleted.id === shape.id)
        );

        // Restore the markers if there are no drawn shapes left
        if (drawnShapes.length === 0) {
          // Restore all original markers
          mapInstance.getSource("markers").setData(allMarkers);
          newMarkers = [];
        }
      });
    });

    const addNewMarkersToMap = async (
      newMarkersData,
      mapInstance,
      drawnPolygon
    ) => {
      // Clear existing markers before adding new ones
      const apiMarkersGeoJson = {
        type: "FeatureCollection",
        features: [], // Initialize with an empty feature collection
      };

      // Filter new markers based on whether they are inside the drawn shape
      const filteredMarkers = newMarkersData.filter((position) => {
        const markerPosition = [
          position.geocodes.main.longitude,
          position.geocodes.main.latitude,
        ];

        // Use Turf.js to check if the marker is within the drawn polygon
        return turf.booleanPointInPolygon(
          turf.point(markerPosition),
          turf.polygon(drawnPolygon.geometry.coordinates)
        );
      });
      newMarkers.push(filteredMarkers);
      const flattenedData = await flattenArray(newMarkers);

      // Convert filtered markers to GeoJSON format
      flattenedData.forEach((data) => {
        apiMarkersGeoJson.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              data.geocodes.main.longitude,
              data.geocodes.main.latitude,
            ],
          },
          properties: {
            name: data.name,
            lat: data.geocodes.main.latitude,
            lng: data.geocodes.main.longitude,
            vicinity: data.location.formatted_address.toString(),
            place_id: data.fsq_id,
            typeName: data.selectedName, // Add typeName to properties
          },
        });
      });

      // Check if markers source exists before updating
      if (mapInstance.getSource("markers")) {
        mapInstance.getSource("markers").setData(apiMarkersGeoJson);
      } else {
        console.error("Markers source not found on the map.");
      }
    };
    // Function to load all custom marker images based on typeName
    function loadCustomMarkerImages(map) {
      const iconMappings = {
        Restaurants: "/assets/images/icons/restaurant-new.png",
        Groceries: "/assets/images/icons/grocery-1.png",
        Parks: "/assets/images/icons/park-1.png",
        Shopping: "/assets/images/icons/shopping-3.png",
        Entertainment: "/assets/images/icons/entertainment-1.png",
        Hospitals: "/assets/images/icons/hos-1.png",
      };

      Object.keys(iconMappings).forEach((key) => {
        map.loadImage(iconMappings[key], (error, image) => {
          if (error) throw error;
          map.addImage(`custom-marker-${key}`, image);
        });
      });
    }

    let dataArray = [];
    if (props.propertyDetails) {
      dataArray = Array.isArray(props.data) ? props.data : [props.data];
    }
    if (props.propertyDetails && Array.isArray(dataArray)) {
      dataArray.forEach((eachdata) => {
        const propertyMarker = new mapboxgl.Marker({
          element: createpropertyMarkerElement(),
        })
          .setLngLat([eachdata.longitude, eachdata.latitude])
          .addTo(mapInstance);

        if (currentPopup) {
          currentPopup.remove();
        }

        const propertypopup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
          <p>${eachdata.address}</p>
        `);
        propertyMarker.setPopup(propertypopup);

        propertypopup.on("open", () => {
          setCurrentPopup(propertypopup);
          setNewState((prev) => ({ ...prev, propertyInfo: true }));
        });

        propertypopup.on("close", () => {
          setNewState((prev) => ({ ...prev, propertyInfo: false }));
        });
      });
    } else {
      console.error("props.data is not an array:", dataArray);
    }
    if (currentMarker) {
      currentMarker.remove(); // This will remove the marker from the map
    }
    if (locationdata.singleProperty) {
      if (currentMarker) {
        currentMarker.remove(); // This will remove the marker from the map
      }
      const singlePropertyMarker = new mapboxgl.Marker({
        element: createSinglePropertyElement(locationdata.singlePropertyIndex),
      })
        .setLngLat([locationdata.longitude, locationdata.latitude])
        .addTo(mapInstance);
      if (currentPopup) {
        currentPopup.remove();
      }
      // Create and set the popup, then attach it to the marker
      const popup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
        <h6>${
          locationdata.propertyName != undefined
            ? locationdata.propertyName
            : newState.propertyName
        }</h6>
             <p>${
               locationdata.propertyAddress != undefined
                 ? locationdata.propertyAddress
                 : newState.propertyAddress
             }</p>
              `);

      // Attach the popup to the marker
      singlePropertyMarker.setPopup(popup);

      // Handle popup open event
      popup.on("open", () => {
        setCurrentPopup(popup);
      });

      // Handle popup close event
      popup.on("close", () => {});
      // Update the current marker reference
      setCurrentMarker(singlePropertyMarker);
    }
    // Handle map loading errors
    mapInstance.on("error", (e) => {
      console.error("Error occurred during map loading:", e);
    });
    function createpropertyMarkerElement() {
      const propertyel = document.createElement("div");
      propertyel.className = "marker";
      propertyel.style.backgroundImage = `url(/assets/images/map-Orange_icon.png)`;
      propertyel.style.backgroundSize = "contain";
      propertyel.style.backgroundRepeat = "no-repeat"; // Prevent repeating
      propertyel.style.backgroundPosition = "center"; // Center the background image
      propertyel.style.width = "32px"; // Adjust size as needed
      propertyel.style.height = "32px"; // Adjust size as needed

      return propertyel;
    }
    function createCustomMarkerElement() {
      const el = document.createElement("div");
      el.className = "marker";
      // Determine the image URL based on state.typeName
      let imageUrl;
      switch (typeName) {
        case "restaurant":
          imageUrl = "/assets/images/icons/restaurant-new.svg";
          break;
        case "supermarket":
          imageUrl = "/assets/images/icons/grocery-1.svg";
          break;
        case "park":
          imageUrl = "/assets/images/icons/park-1.svg";
          break;
        case "shopping_mall":
          imageUrl = "/assets/images/icons/shopping-3.svg";
          break;
        case "night_club":
          imageUrl = "/assets/images/icons/entertainment-1.svg";
          break;
        default:
          imageUrl = "/assets/images/icons/hos-1.svg";
          break;
      }
      el.style.backgroundImage = `url(${imageUrl})`;
      el.style.backgroundSize = "contain";
      el.style.backgroundRepeat = "no-repeat"; // Prevent repeating
      el.style.backgroundPosition = "center"; // Center the background image
      el.style.width = "32px"; // Adjust size as needed
      el.style.height = "32px"; // Adjust size as needed
      return el;
    }
    function createSinglePropertyElement(index) {
      // console.log(index, "index index");

      const singlepropertyel = document.createElement("div");
      singlepropertyel.className = "marker";
      singlepropertyel.textContent =
        index != undefined ? index : newState.propertyIndex;
      // Add CSS styles to the element
      singlepropertyel.style.backgroundImage = `url(/assets/images/mapNew.png)`;
      singlepropertyel.style.backgroundSize = "contain";
      singlepropertyel.style.backgroundRepeat = "no-repeat";
      singlepropertyel.style.backgroundPosition = "center";
      singlepropertyel.style.width = "40px";
      singlepropertyel.style.height = "40px";
      singlepropertyel.style.zIndex = "999";
      singlepropertyel.style.display = "flex";
      singlepropertyel.style.alignItems = "center";
      singlepropertyel.style.justifyContent = "center";
      singlepropertyel.style.color = "#fff"; // Text color
      singlepropertyel.style.fontSize = "14px"; // Text size
      singlepropertyel.style.fontWeight = "bold"; // Text weight
      // singlepropertyel.style.textShadow = "1px 1px 2px rgba(0, 0, 0, 0.7)"; // Text shadow for better readability
      return singlepropertyel;
    }
    // setTimeout(() => {
    //   setState((prev) => ({ ...prev, Loader: false }));
    // }, 2000);
  };
  const flattenArray = (nestedArray) => {
    // Use reduce to combine all sub-arrays into a single array
    return nestedArray.reduce((acc, currentArray) => {
      return acc.concat(currentArray);
    }, []);
  };
  const onetype = async (row, type) => {
    // console.log(row, "===", type);
    setNewState((prev) => ({
      Loader: true,
      ...prev,
      restaurantsArr: [],
      typesList: [],
      restaurantsList: [],
    }));
    if (row.type == "restaurant") {
      let lat = newState.mapLatitude;
      let lng = newState.mapLongitude;
      let type = row.type;
      let restaurantId = "4bf58dd8d48988d14c941735";
      let apiObject = {
        latitude: newState.mapLatitude,
        longitude: newState.mapLongitude,
        radius: 8000,
      };
      // loadPlacesWithMarkers(lat, lng, row.type);
      loadPlacesWithFourSquare(apiObject, restaurantId);
      setNewState((prev) => ({
        ...prev,
        type: row.type,
        showlist: false,
        typeName: row.name,
        typeicon: type,
        Loader: false,
      }));
    } else {
      let lat = newState.mapLatitude;
      let lng = newState.mapLongitude;
      let type = row.type;
      loadPlacesWithMarkers(lat, lng, row.type);
      setNewState((prev) => ({
        ...prev,
        type: row.type,
        showlist: false,
        typeName: row.name,
        typeicon: type,
        restlistarray: [],
        Loader: false,
      }));
    }
  };

  const newmultipleTypes = async (row, type, index, isChecked) => {
    // console.log(row, "===", type, index, isChecked);
    // closePopupIfOpen();

    setNewState((prev) => ({ ...prev, Loader: true, activeNumber: "" }));
    let updatedTypes = [...newState.updatedTypes];

    if (isChecked) {
      // console.log("checked");

      if (!updatedTypes.includes(type)) {
        // console.log("not included");

        updatedTypes.push(type);
      }
    } else {
      updatedTypes = updatedTypes.filter((item) => item !== type);
    }

    setNewState((prev) => ({
      ...prev,
      typeName: updatedTypes,
      updatedTypes: updatedTypes,
    }));

    // let allData = [...state.totalData];

    if (isChecked) {
      console.log(isChecked, "is checked");

      let dataaaa = [
        { key: "Restaurants", id: "4bf58dd8d48988d14c941735" },
        { key: "Groceries", id: "4bf58dd8d48988d118951735" },
        { key: "Parks", id: "4bf58dd8d48988d163941735" },
        { key: "Shopping", id: "4bf58dd8d48988d1fd941735" },
        { key: "Entertainment", id: "4d4b7104d754a06370d81259" },
        { key: "Hospitals", id: "4bf58dd8d48988d196941735" },
      ];
      let obj = dataaaa.find((o) => o.key == row.name.toString());
      let apiObject = {
        latitude: newState.mapLatitude,
        longitude: newState.mapLongitude,
        radius: 8000,
      };
      let GetFourSquare = await AppController.fourSquarData(apiObject, obj.id);

      GetFourSquare = GetFourSquare.map((data) => {
        const { fsq_id, geocodes, location, name, rating } = data;
        return {
          fsq_id,
          geocodes,
          location,
          name,
          rating,
          selectedName: row.name, // Add `selectedName` key with the value from `row.name`
        };
      });

      // allData = [...allData, ...GetFourSquare];
      let updatedTotalData = [...newState.NearPlaces, ...GetFourSquare];
      let typesArray = [...newState.typesArray, obj.id];
      let locationData = {
        latitude: newState.mapLatitude,
        longitude: newState.mapLongitude,
        singleProperty: newState.singleProperty,
      };

      setNewState((prev) => ({
        ...prev,
        NearPlaces: [...prev.NearPlaces, ...GetFourSquare],
        showlist: false,
        typesArray: [...prev.typesArray, obj.id],
        Loader: false,
      }));
      insertnewMap(updatedTotalData, locationData, typesArray);
    } else {
      if (updatedTypes.length != 0) {
        console.log(isChecked, "unchecked");
        let totalData = newState.NearPlaces;
        let filterData = totalData.filter((data) => {
          return data.selectedName !== row.name;
        });

        // Find the corresponding ID to remove
        let dataaaa = [
          { key: "Restaurants", id: "4bf58dd8d48988d14c941735" },
          { key: "Groceries", id: "4bf58dd8d48988d118951735" },
          { key: "Parks", id: "4bf58dd8d48988d163941735" },
          { key: "Shopping", id: "4bf58dd8d48988d1fd941735" },
          { key: "Entertainment", id: "4d4b7104d754a06370d81259" },
          { key: "Hospitals", id: "4bf58dd8d48988d196941735" },
        ];
        let obj = dataaaa.find((o) => o.key == row.name.toString());
        let typesArray = newState.typesArray.filter((id) => id !== obj.id);
        let locationData = {
          latitude: newState.mapLatitude,
          longitude: newState.mapLongitude,
          singleProperty: newState.singleProperty,
        };
        setNewState((prev) => ({
          ...prev,
          NearPlaces: filterData,
          typesArray: prev.typesArray.filter((id) => id !== obj.id),
          Loader: false,
        }));
        insertnewMap(filterData, locationData, typesArray);
      } else {
        console.log("unchecked by else");
        let locationData = {
          latitude: newState.mapLatitude,
          longitude: newState.mapLongitude,
          singleProperty: newState.singleProperty,
        };
        setNewState((prev) => ({
          ...prev,
          totalData: [],
          totalSelectedData: [],
          selectedTypeData: [],
          NearPlaces: [],
          typesArray: [],
          latitude: props.latitude,
          longitude: props.longitude,
          Loader: false,
        }));
        insertnewMap([], locationData, newState.typesArray);
      }
    }

    // console.log(allData, "allData");
  };
  const propertyNearBy = async (event, index) => {
    try {
      // console.log(event, "event ofpropertyNearBy", index);
      if (!map) {
        console.error("Map instance is not available.");
        return; // Exit if map is not defined
      }
      let singlePropertyData = {
        latitude: event.latitude,
        longitude: event.longitude,
        singleProperty: true,
        propertyAddress: event.address,
        propertyName: event.propertyname,
        singlePropertyIndex: index,
      };
      let dataaaa = [
        { key: "Restaurants", id: "4bf58dd8d48988d14c941735" },
        { key: "Groceries", id: "4bf58dd8d48988d118951735" },
        { key: "Parks", id: "4bf58dd8d48988d163941735" },
        { key: "Shopping", id: "4bf58dd8d48988d1fd941735" },
        { key: "Entertainment", id: "4d4b7104d754a06370d81259" },
        { key: "Hospitals", id: "4bf58dd8d48988d196941735" },
      ];

      let apiObject = {
        latitude: event.latitude,
        longitude: event.longitude,
        radius: 8000,
      };
      let restaurantId = "4bf58dd8d48988d14c941735";
      let fourSquareData;
      if (newState.updatedTypes.length == 0) {
        console.log("no option has been selected");

        fourSquareData = await AppController.fourSquarData(
          apiObject,
          restaurantId
        );
        fourSquareData = fourSquareData.map((data) => {
          const { fsq_id, geocodes, location, name, rating } = data;
          return {
            fsq_id,
            geocodes,
            location,
            name,
            rating,
            selectedName: "Restaurants", // Add `selectedName` key with the value from `row.name`
          };
        });
        let obj = dataaaa.find((cat) => cat.id === restaurantId);
        let typesArray = [...newState.typesArray, obj.id];
        setNewState((prev) => ({
          ...prev,
          typesArray: [...prev.typesArray, obj.id],
          updatedTypes: [...prev.updatedTypes, "Restaurants"],
        }));
        insertnewMap(fourSquareData, singlePropertyData, typesArray);
      } else {
        console.log(" option  selected");
        const fetchPromises = newState.typesArray.map(async (type) => {
          // Make the API call
          const response = await AppController.fourSquarData(apiObject, type);

          // Check if response has results and attach typeId and selectedName
          if (response) {
            return response.map((item) => {
              const { fsq_id, geocodes, location, name, rating } = item;

              // Find the matching category from `dataaaa` based on `typeId`
              const category = dataaaa.find((cat) => cat.id === type);
              const selectedName = category ? category.key : "Unknown";

              return {
                fsq_id,
                geocodes,
                location,
                name,
                rating,
                selectedName, // Add `selectedName` key with the value from the category
              };
            });
          }
          return [];
        });

        // Wait for all promises to resolve and flatten the results
        const results = await Promise.all(fetchPromises);
        fourSquareData = results.flat();
        insertnewMap(fourSquareData, singlePropertyData, newState.typesArray);
      }

      setNewState((prev) => ({
        ...prev,
        singlePropertyData: {
          latitude: event.latitude,
          longitude: event.longitude,
          propertyAddress: event.address,
          propertyName: event.propertyname,
        },
        NearPlaces: fourSquareData,
        // latitude: event.latitude,
        // longitude: event.longitude,
        propertyLatitude: event.latitude,
        propertyLongitude: event.longitude,
        mapLatitude: event.latitude,
        mapLongitude: event.longitude,
        isProperty: true,
        propertyAddress: event.address,
        propertyName: event.propertyname,
        propertyIndex: index,
        singleProperty: true,
      }));
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleMarkerInfo = (data) => {
    // console.log("handlemarker", data);
    setNewState((prev) => ({
      ...prev,
      activeMaker: data,
      activeNumber: data.name,
      // latitude: data.geometry.location.lat,
      // longitude: data.geometry.location.lng,
      mapLatitude: data.geometry.location.lat,
      mapLongitude: data.geometry.location.lng,
    }));
  };
  const clearActiveMarker = () => {
    setNewState((prev) => ({ ...prev, activeMaker: null }));
  };
  const loadPlacesWithFourSquare = async (apiObject, type) => {
    try {
      const fourSquareData = await AppController.fourSquarData(apiObject, type);
      // console.log(fourSquareData, "fourSquarData");

      const transformedData = fourSquareData.map((item) => ({
        business_status: "OPERATIONAL",
        geometry: {
          location: {
            lat: item.geocodes.main.latitude,
            lng: item.geocodes.main.longitude,
          },
          // viewport: {
          //   northeast: {
          //     lat:
          //       item.venue.location.labeledLatLngs[0]?.lat ||
          //       item.venue.location.lat,
          //     lng:
          //       item.venue.location.labeledLatLngs[0]?.lng ||
          //       item.venue.location.lng,
          //   },
          //   southwest: {
          //     lat: item.venue.location.lat,
          //     lng: item.venue.location.lng,
          //   },
          // },
        },
        name: item.name,
        place_id: item.fsq_id,
        vicinity: item.location.formatted_address.toString(),
      }));

      // console.log(transformedData, "Transformed Data");

      setNewState((prev) => ({
        ...prev,
        NearPlaces: transformedData,
      }));
      let data = {
        latitude: apiObject.latitude,
        longitude: apiObject.longitude,
        singleProperty: newState.singleProperty,
      };
      let restaurantType = "restaurant";
      insertnewMap(transformedData, restaurantType, data);
      return transformedData;
    } catch (error) {
      console.log(error, "error");
    }
  };
  const loadPlacesWithMarkers = async (lat, lng, type) => {
    // console.log("clicked the markers function", lat, lng, type);
    let GetNearPlaces = await GoogleMapsPlaces.GetNearByPlaces({
      latitude: lat,
      longitude: lng,
      type: type,
      keyword: type,
      radius: 4000,
    });
    // console.log(GetNearPlaces.results, "GetFourSquare");
    setNewState((prev) => ({
      ...prev,
      NearPlaces: GetNearPlaces.results,
    }));
    let data = {
      latitude: lat,
      longitude: lng,
      singleProperty: newState.singleProperty,
    };
    insertnewMap(GetNearPlaces.results, type, data);
  };
  const propertyDetailsInfo = () => {
    setNewState((prev) => ({ ...prev, propertyDetails: true }));
  };
  const clearpropertyDetails = () => {
    setNewState((prev) => ({ ...prev, propertyDetails: false }));
  };
  const propertyDataInfo = () => {
    setNewState((prev) => ({ ...prev, propertyData: true }));
  };
  const clearpropertyData = () => {
    setNewState((prev) => ({ ...prev, propertyData: false }));
  };
  const moreInfoWithDetails = async (row) => {
    await localStorage.setItem("myPropertyDetails", JSON.stringify(row));
    // $("document").ready(function () {
    //   $("#propertymap").modal("hide");
    // });
    $(function () {
      $(document).ready(async function () {
        $("#comparemap").modal("hide");
        $("#propertymap").modal("hide");
        $("body").removeAttr("style");
        $(".modal-backdrop").remove(); // Hide the modal backdrop
      });
    });

    // window.open("/my_property_details", "myWindow");
    navigate("/my_property_details");
  };

  function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  const updateCarouselPosition = (object) => {
    if (object.item.index != newState.startPosition) {
      setNewState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };
  const tab = () => {
    let tabItems =
      parseInt(screen.availWidth) <= 767
        ? 2
        : parseInt(screen.availWidth) <= 991
        ? 2
        : 3;
    setNewState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };
  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  return (
    // <div>
    //   <div>
    <div className="city-landing-page newOne">
      {newState.Loader == true ? <div className="loading"></div> : null}
      <div className="tabs-view custommultiCheckBox ">
        <ul className="nav nav-pills" role="tablist">
          {newState.newtypes.map((type, indexx) => {
            return (
              <div className="item" key={type.name}>
                <div className="formrow">
                  <input
                    className="checkbox"
                    type="checkbox"
                    name={`check${indexx}${indexx}`}
                    id={`check${indexx}${indexx}`}
                    checked={newState.updatedTypes.includes(type.name)}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      newmultipleTypes(type, type.name, indexx, isChecked);
                    }}
                  />
                  <label
                    className="checklabel"
                    htmlFor={`check${indexx}${indexx}`}
                  >
                    <i className={type.faicon}></i> {type.name}
                  </label>
                </div>
              </div>
            );
          })}
        </ul>
        <div className="row sharlotte-view tab-content ">
          <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
            <div id="mapview" className="relomap-height">
              <div id="map1" style={{ width: "100%", height: "100%" }}></div>
            </div>{" "}
          </div>

          <div className="col-md-4 p-0">
            <div className="map-right-view rounded-end-4">
              <span className="toggleArrow" onClick={() => heightView()}>
                <i class="fa-solid fa-angle-up"></i>
              </span>
              <div className="heightGrid">
                {/* <div className="slider tabSlider mb-3">
                <OwlCarousel
                  className="owl-theme"
                  dots={false}
                  items={state.tabItems}
                  startPosition={state.startPosition}
                  onDragged={(object) => updateCarouselPosition(object)}
                  onTranslated={(object) => updateCarouselPosition(object)}
                >
                  {state.types.map((type, index) => (
                    <div key={index} className="item">
                      <a
                        className={`nav-item${
                          (state.typeName === ""
                            ? " Restaurants"
                            : state.typeName) === type.name
                            ? " active"
                            : ""
                        }`}
                        data-toggle="tab"
                        id={type.name.replace(/[^a-zA-Z]/g, "")}
                        onClick={() => onetype(type, index)}
                      >
                        <i className={type.faicon}></i>
                        {type.name}
                      </a>
                    </div>
                  ))}
                </OwlCarousel>
              </div> */}
                {props.propertyDetails && (
                  <div className="propertyresulttext">
                    {props.myProp.length > 0 ? (
                      props.myProp.map((row, index) => {
                        return (
                          <div className="shResultspageimages" key={index}>
                            <span className="ScoreLabel sharlotte">
                              <Popover
                                placement="leftTop"
                                content={
                                  <div>
                                    {row.propertyrankitems.propertyrankingitems.map(
                                      (Pitem) => {
                                        return (
                                          <p
                                            key={
                                              "sharlotte" +
                                              index +
                                              Pitem.prioritylistitem
                                            }
                                          >
                                            {Pitem.prioritylistitem ==
                                            "Must Haves" ? (
                                              <Popover
                                                placement="leftTop"
                                                content={
                                                  <div>
                                                    {Pitem.matcheditems.map(
                                                      (matcheditem) => {
                                                        return (
                                                          matcheditem.itemname !=
                                                            "" && (
                                                            <p
                                                              key={
                                                                "sharlottepps" +
                                                                index +
                                                                matcheditem.itemname
                                                              }
                                                            >
                                                              {
                                                                matcheditem.itemname
                                                              }
                                                              {matcheditem.ismatched ==
                                                                true && (
                                                                <i className="fa fa-check"></i>
                                                              )}
                                                              {matcheditem.ismatched ==
                                                                false && (
                                                                <i className="fa fa-times"></i>
                                                              )}
                                                            </p>
                                                          )
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                }
                                                title="Matched Must Haves"
                                                trigger="hover"
                                              >
                                                {Pitem.prioritylistitem}{" "}
                                                <i
                                                  className="fa fa-info-circle"
                                                  style={{
                                                    color: "#000000",
                                                  }}
                                                ></i>
                                              </Popover>
                                            ) : (
                                              Pitem.prioritylistitem
                                            )}
                                            {Pitem.ismatched == true &&
                                              Pitem.isallmatched == false && (
                                                <i className="fa fa-check yellow_clr"></i>
                                              )}
                                            {Pitem.ismatched == true &&
                                              Pitem.isallmatched == true && (
                                                <i className="fa fa-check"></i>
                                              )}
                                            {Pitem.ismatched == true &&
                                              Pitem.isallmatched == null && (
                                                <i className="fa fa-check"></i>
                                              )}
                                            {Pitem.ismatched == false && (
                                              <i className="fa fa-times"></i>
                                            )}
                                          </p>
                                        );
                                      }
                                    )}
                                  </div>
                                }
                                title="Matched Priorities"
                                trigger="hover"
                              >
                                {Math.trunc(row.propertyrankitems.matchpercent)}
                                %
                              </Popover>
                            </span>
                            <div
                              className={
                                newState.propertyIndex == index + 1
                                  ? "shr_textmain active "
                                  : "shr_textmain "
                              }
                            >
                              {/* <div
                                className={
                                  state.propertyIndex == index + 1
                                    ? "selectnumber "
                                    : "selectnumber "
                                }
                                onClick={() => propertyNearBy(row, index + 1)}
                              >
                                {index + 1}
                              </div> */}
                              <div
                                className="shr_text"
                                // onClick={() => props.mapIndexChange(row, index)}
                                onClick={() => propertyNearBy(row, index + 1)}
                                style={{ cursor: "pointer" }}
                              >
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => moreInfoWithDetails(row)}
                                >
                                  {row.propertyname}{" "}
                                  <i className="fa-regular fa-arrow-up-right"></i>
                                </a>
                                <span> {row.address} </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <span className="MapEmptyText">
                        <Link to="/myProperty">
                          Please add rentals to see here
                        </Link>
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  );
}

export default NewNearByPlaces;

import React from "react";
import moment from "moment";

const DetailsCard = ({ moredetails, companyName }) => {
  return (
    <div className="profile-item">
      <div className="profile">
        <h1>{moredetails.name}</h1>
        <h2>{moredetails.EmailId}</h2>
        <h3>{moredetails.PhoneNumber}</h3>
      </div>
      <ul>
        {/* <li>
          {" "}
          <span>Company : </span> <b>{companyName}</b>{" "}
        </li> */}
        <li>
          {" "}
          <span>Service Level : </span> <b>{moredetails.subscriptioncode}</b>{" "}
        </li>
        {moredetails.accessfromdate != null && (
          <li>
            {" "}
            <span>Access From : </span>{" "}
            <b>{moment(moredetails.accessfromdate).format("MM/DD/YYYY")}</b>{" "}
          </li>
        )}
        {moredetails.accesstodate != null && (
          <li>
            {" "}
            <span>Access To : </span>{" "}
            <b>{moment(moredetails.accesstodate).format("MM/DD/YYYY")}</b>{" "}
          </li>
        )}
      </ul>
    </div>
  );
};

export default DetailsCard;
